import React from "react";
import {
  Button,
} from "reactstrap";
import { Link } from "react-router-dom";
import s from "./WaitList.module.scss";

import errorImage from "../../assets/errorImage.svg"
import waitlistImage from "../../assets/waitlist.jpg"
import FooterIconCoffee from "../../components/Icons/FooterIconCoffee";
import FooterIcon from "../../components/Icons/FooterIcon.js";
import { Widget } from '@typeform/embed-react'
import { useAuth } from '@frontegg/react';

const setFormCompleted = () => {
  localStorage.setItem('formCompleted', 'true');
};

const WaitList = () => {
  const { user, isAuthenticated } = useAuth();
  const formCompleted = localStorage.getItem('formCompleted');

  return (
    <div className={s.pageContainer} style={{height: "100%"}}>
      <div className={s.errorContainer} style={{margin: "0px auto"}}>
        <h1 className={s.errorCode}>Waitlist</h1>
        <p className={s.errorInfo}>
          Thanks for your interest in Docugrid! We're currently in private beta. 
        </p>
      </div>
	    {!formCompleted && <Widget id="Arry7iDi"
              hidden={{full_name: user.name, email: user.email, form_completed: formCompleted}}
              style={{ margin: 'auto', width: '90%', height: '600px' }}
              onSubmit={setFormCompleted}
              className="my-form" />}
      {formCompleted && <div className={s.errorContainer}>
        <img src={waitlistImage}
          style={{width: "70%"}}>
        </img>
        <p>Thanks for filling out the form. We've moved up your position in the waitlist!</p>
        </div>}
      <div className={s.errorContainer}>
        <p className={s.errorHelp}>
          Our team has been notified you have signed up (really, we have)! We are working through the waitlist, but if you want faster access email hello@docugrid.io.
        </p>
        {/* <Link to="/dashboard">
          <Button className={`${s.errorBtn} rounded-pill`} type="submit" color="secondary-red">
            Back to Home
          </Button>
        </Link> */}
      </div>
      {/* <div className={s.footer}>
      <span className={s.footerLabel}>2022 &copy; Docugrid. Powered by</span>
      <FooterIconCoffee />
      <span className={s.footerLabel}>, crafted with</span> <FooterIcon />
    </div> */}
    </div>
  );
}

export default WaitList;
