import React, { useState} from "react";
import { v4 as uuidv4 } from "uuid";
import {
  Col,
  Row,
  Table,
  Pagination,
  PaginationItem,
  PaginationLink,
  ButtonDropdown,
  Dropdown,
  DropdownMenu,
  DropdownToggle,
  DropdownItem,
  Label,
  Badge,
} from "reactstrap";
import Widget from "../../components/Widget/Widget.js";
import TaskContainer from "./components/TaskContainer/TaskContainer.js";

import {
  basename
} from "../../actions/utils";

// import BootstrapTable from "react-bootstrap-table-next";
// import paginationFactory from 'react-bootstrap-table2-paginator';
// import MUIDataTable from "mui-datatables";

import cloudIcon from "../../assets/tables/cloudIcon.svg";
import funnelIcon from "../../assets/tables/funnelIcon.svg";
import optionsIcon from "../../assets/tables/optionsIcon.svg";
import printerIcon from "../../assets/tables/printerIcon.svg";
import searchIcon from "../../assets/tables/searchIcon.svg";
import moreIcon from "../../assets/tables/moreIcon.svg";

import s from "./Tables.module.scss";
import mock from "./mock.js";

import { Viewer, SpecialZoomLevel} from '@react-pdf-viewer/core';

// Plugins
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';

// Import styles
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import Notification from "../../components/Notification/Notification.js";
import { useParams } from "react-router-dom";
import LinearProgress from "@material-ui/core/LinearProgress";

class ContractTable extends React.Component {

  constructor(props) {
    super(props);
    console.log("PROPS", props);
    this.state = {
      selectedContractID: "",
      showPDF: false,
      tableCurrentPage: 0,
      tableDropdownOpen: false,
      isProgressing: true,
      checkedDocuments: [],
      checkAll: false,
    };
  }

  handleCheckAll = (event) => {
    const contracts = this.props.contracts;
    var newCheckedDocuments = [];
    if (event.target.checked) {
      for (var i = 0; i < contracts.length; i++) {
        newCheckedDocuments.push(contracts[i].response.id);
      }
    }
    this.setState({checkAll: event.target.checked, checkedDocuments: newCheckedDocuments});
  }

  handleCheck = (event) => {
    var updatedList = [...this.state.checkedDocuments];
    if (event.target.checked) {
      updatedList = [...this.state.checkedDocuments, event.target.value];
    } else {
      updatedList.splice(this.state.checkedDocuments.indexOf(event.target.value), 1);
    }
    this.setState({checkedDocuments: updatedList});
  };
  isDocumentChecked = (document_id) => {
    return this.state.checkedDocuments.includes(document_id);
  }

  showContract(id) {
    console.log("showContract", id);
    // this.setState({showPDF: true, selectedContractID: id});
    // this.history.push("/contract/1231231");
  }

  base64toBlob = (data) => {
      // Cut the prefix `data:application/pdf;base64` from the raw base 64
      const base64WithoutPrefix = data.substr('data:application/pdf;base64,'.length);

      const bytes = atob(base64WithoutPrefix);
      let length = bytes.length;
      let out = new Uint8Array(length);

      while (length--) {
          out[length] = bytes.charCodeAt(length);
      }

      return new Blob([out], { type: 'application/pdf' });
  };

  getContracts = (page) => {
    console.log("getContracts");
    this.setState({
      "isProgressing": true,
    });
    const token = this.props.token;
    let contractsURL = "https://docugrid-api.herokuapp.com/contracts/all"
    if(this.props.pageName.localeCompare("myTenantContracts") === 0){
      console.log("myTenantContracts");
      contractsURL = "https://docugrid-api.herokuapp.com/contracts/all?tenant=true"
    }
    fetch(contractsURL, {
      method: 'GET',
      headers: {
        'Authorization': "Bearer " + token,
      }
    })
    .then(res => res.json())
    .then(
        (result) => {
          // this.setState({"contracts": result});
          console.log("got contract results", result);
          const contracts = [];
          // for (const [key, value] of Object.entries(result)) {
          //   console.log(`${key}: ${value}`);
          //   contracts.push(value);
          // }
          
          this.setState({
            isLoaded: true,
            isProgressing: false,
          });
          if('result' in result){
          this.props.setContracts(result['result']);
          // this.props.setContractsMap(result);
          }
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          console.log("error", error)
        }
      )
  };

  onChangeCheck = (event) => {
    console.log(event);
  };

  deleteContractFromSearchIndex = (document_id) => {
    const token = this.props.token;

    fetch(`https://docugrid-flask.ngrok.io/delete_contract_from_search_index`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': "*",
        'Authorization': "Bearer " + token,
      },
      body: JSON.stringify({
        "document_id": document_id,
      }),
    })
    .then(res => res.json())
    .then(
        (result) => {
          // this.setState({"contracts": result});
          console.log("delete contract from search index", result);
          this.setState({
            isProgressing: false,
          });
          this.getContracts(0);
          this.props.history.push(`/documents`);
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          console.log("error", error)
        }
      )

  };
  deleteCheckedContracts = () => {
    for (var i = 0; i < this.state.checkedDocuments.length; i++) {
      this.deleteContract(this.state.checkedDocuments[i]);
    }
  }

  deleteContract = (document_id) => {
    const token = this.props.token;

    this.setState({
      isProgressing: true,
    });

    fetch(`https://docugrid-api.herokuapp.com/contracts?document_id=`+document_id, {
      method: 'DELETE',
      headers: {
        'Authorization': "Bearer " + token,
      }
    })
    .then(res => res.json())
    .then(
        (result) => {
          // this.setState({"contracts": result});
          console.log("delete contract from DB", result);
          this.deleteContractFromSearchIndex(document_id);
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          console.log("error", error)
        }
      )

  };

  componentDidMount() {
    const pdfjs = require('pdfjs-dist');
    pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;
    // pdfjs.GlobalWorkerOptions.workerSrc = `https://docugrid.ngrok.io/pdf.worker.js`;

    this.getContracts(this.state.tableCurrentPage);

  }

  render() {
    console.log("PROPS ID" , this.props.match.params.document_id);
    var pdfFileContents = "";
    var selectedContract = undefined;
    const selectedContractID = this.props.match.params.document_id;
  
    const tableCurrentPage = this.state.tableCurrentPage;
    const tableDropdownOpen = this.state.tableDropdownOpen;
    const contracts = this.props.contracts;

    const pageSize = 4;
    const secondTablePagesCount = 10;

    const setSecondTablePage = (e, index) => {
      e.preventDefault();
      this.setState({'tableCurrentPage': index})
      this.getContracts(index+1);
    }

    console.log(this.state.checkedDocuments);


    const tableMenuOpen = () => {
      this.setState({'tableDropdownOpen': !tableDropdownOpen});
    }

    return (
      <div>
        {this.state.isProgressing && <LinearProgress />}
        <Row>
          <Col>
            <Row className="mb-4">
              <Col>
                <Widget>
                  <div className={s.tableTitle}>
                  <div className="headline-2">{(this.props.pageName.localeCompare("myTenantContracts") === 0) ? 'My Team Documents': 'My Documents'}</div>
                  <div className="mb-4">{(this.props.pageName.localeCompare("myTenantContracts") === 0) ? 'Documents uploaded by other members of my team/tenant': 'Documents uploaded by me'}</div>
                    <Dropdown
                      className="d-none d-sm-block"
                      nav
                      isOpen={tableDropdownOpen}
                      toggle={() => tableMenuOpen()}
                    >
                      <DropdownToggle nav>
                        <img className="d-none d-sm-block" src={moreIcon} alt="More..."/>
                      </DropdownToggle>
                      <DropdownMenu >
{/* REMOVING COPY AND EDIT FOR NOW */}
{/*                         <DropdownItem>
                          <div>Copy</div>
                        </DropdownItem>
                        <DropdownItem>
                          <div>Edit</div>
                        </DropdownItem> */}
                        <DropdownItem>
                          <div  onClick={event => this.deleteCheckedContracts()}>Delete</div>
                        </DropdownItem>
                      </DropdownMenu>
                    </Dropdown>
                  </div>
                  <div className="widget-table-overflow">
                    <Table className="table-striped table-borderless table-hover" responsive>
                      <thead>
                      <tr>
                        <th>
                          <div className="checkbox checkbox-primary">
                            <input
                              id="checkbox200"
                              checked={this.state.checkAll}
                              onChange={this.handleCheckAll}
                              className="styled"
                              type="checkbox"
                            />
                            <label htmlFor="checkbox200"/>
                          </div>
                        </th>
                        <th className={s.nameCol}>FILENAME</th>
                        {this.props.pageName.localeCompare("myTenantContracts") === 0 && <th className={s.nameCol}>UPLOADED BY</th> }
                        {/* <th className={s.nameCol}>DOCUMENT NAME</th>
                        <th>PARTIES</th>
                        <th className="not_import_for_mobile" >SUMMARY</th>
                        <th className="not_import_for_mobile">EFFECTIVE DATE</th>
                        <th className="not_import_for_mobile">UPCOMING EXPIRY DATE</th> */}
                        <th className="not_import_for_mobile">UPLOADED ON</th>
                        <th className="not_import_for_mobile">STATUS</th>
                      </tr>
                      </thead>
                      <tbody>
                      {contracts
                        .map(item => (
                        // <tr key={uuidv4()} onClick={() => this.showContract(item.response.id)}>
                       <tr key={uuidv4()} >

                          <td>
                            <div className="checkbox checkbox-primary" >
                              <input
                                value={item.response.id}
                                className="styled"
                                type="checkbox"
                                checked={this.isDocumentChecked(item.response.id)}
                                onChange={this.handleCheck}
                              />
                              <label htmlFor={item.id} />
                            </div>
                          </td>
                          <td onClick={event => this.props.history.push(`/document/${item.response.id}`)}>{basename(item['filename']).substring(0, 30)+"..." || "Filename"}</td>
                          {this.props.pageName.localeCompare("myTenantContracts") === 0 && <td onClick={event => this.props.history.push(`/document/${item.response.id}`)}>{(item['owner_email'] || "Me").substring(0, (item['owner_email'] || "Me").lastIndexOf("@")) || "Me"}</td>}
                          {/* <td onClick={event => this.props.history.push(`/document/${item.response.id}`)}>{item.response['Document Name'] || "Document"}</td>
                          <td onClick={event => this.props.history.push(`/document/${item.response.id}`)}>{item.response['Parties']}</td>
                          <td className="not_import_for_mobile"  onClick={event => this.props.history.push(`/document/${item.response.id}`)}>{('Summary' in item.response && item.response['Summary'].substring(0, 100)+"...") || "N/A"}</td>
                          <td className="not_import_for_mobile" onClick={event => this.props.history.push(`/document/${item.response.id}`)}>{item.response['start_date'] || "N/A"}</td>
                          <td className="not_import_for_mobile" onClick={event => this.props.history.push(`/document/${item.response.id}`)}>{item.response['upcoming_expiry_date'] || "N/A"}</td> */}
                        <td className="not_import_for_mobile" onClick={event => this.props.history.push(`/document/${item.response.id}`)}>{new Date(item['created_at']*1000).toLocaleString("lookup") || "N/A"}</td>
                          <td className="not_import_for_mobile" onClick={event => this.props.history.push(`/document/${item.response.id}`)}>
                              {item['status'] !== undefined && item['status'] === "ready" && <Badge color='success'>Processed</Badge>}
                              {item['status'] === undefined || item['status'] !== "ready" && <Badge color='secondary-yellow'>Processing</Badge>}
                          </td>

                        </tr>
                      ))}
                      </tbody>
                    </Table>
                    {/* <Pagination className="pagination-with-border">
                      <PaginationItem disabled={tableCurrentPage <= 0}>
                        <PaginationLink
                          onClick={e => setSecondTablePage(e, tableCurrentPage - 1)}
                          previous
                          href="#top"
                        />
                      </PaginationItem>
                      {[...Array(secondTablePagesCount)].map((page, i) =>
                        <PaginationItem active={i === tableCurrentPage} key={i}>
                          <PaginationLink onClick={e => setSecondTablePage(e, i)} href="#top">
                            {i + 1}
                          </PaginationLink>
                        </PaginationItem>
                      )}
                      <PaginationItem disabled={tableCurrentPage >= secondTablePagesCount - 1}>
                        <PaginationLink
                          onClick={e => setSecondTablePage(e, tableCurrentPage + 1)}
                          next
                          href="#top"
                        />
                      </PaginationItem>
                    </Pagination> */}
                  </div>
                </Widget>
              </Col>
            </Row>
            
          </Col>
        </Row>
      </div>
    )
  }
}

export default ContractTable;
