// -- React and related libs
import React from 'react';
import { render } from 'react-dom';

// -- Redux
import { routerMiddleware } from "connected-react-router";
import { createStore, applyMiddleware, compose } from "redux";
import ReduxThunk from "redux-thunk";
import { Provider } from 'react-redux';
import reducers from './reducers';
import { doInit } from "./actions/auth";

import './fakeDB';

// -- App
import App from './App';
import createRootReducer from './reducers';

// -- Service Worker
import * as serviceWorker from './serviceWorker';

import { FronteggProvider } from '@frontegg/react';

import LogRocket from 'logrocket';
import TagManager from 'react-gtm-module'

LogRocket.init('ruurb9/docugrid', {
  mergeIframes: true,
});

const tagManagerArgs = {
  gtmId: 'G-4MJR88JF83'
}

TagManager.initialize(tagManagerArgs)

// -- Data Store
export const store = createStore(
  reducers,
  applyMiddleware(ReduxThunk)
);

store.dispatch(doInit());

const contextOptions = {
  baseUrl: 'https://auth.docugrid.io/',
  clientId: '63f7160b-f85e-4ce8-8132-2c5880fd6c10',
};

// -- Rendering Application
render(
  <FronteggProvider contextOptions={contextOptions}>
    <Provider store={store}>
      <App/>
    </Provider>
  </FronteggProvider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();

