// -- React and related libs
import React, { useState } from "react";
import { connect } from "react-redux";
import { Switch, Route, withRouter, Redirect } from "react-router";

// -- Third Party Libs
import PropTypes from "prop-types";

// -- Custom Components
import Header from "../Header/Header";
import Sidebar from "../Sidebar/Sidebar";
import Footer from "../Footer/Footer";
import Breadcrumbs from "../Breadbrumbs/Breadcrumbs";
import Profile from "../../pages/profile/Profile";
import Upload from "../../pages/upload/Upload";
import Calendar from "../../pages/calendar/Calendar";
import Chat from "../../pages/chat/Chat";
import Notifooications from "../../pages/notifications/Notifications";
import Tables from "../../pages/tables/Tables";
import Charts from "../../pages/uielements/charts/Charts";
import Icons from "../../pages/uielements/icons/IconsPage";
import Maps from "../../pages/uielements/maps/google/GoogleMapPage";

// -- Component Styles
import s from "./Layout.module.scss";
import ContractTable from "../../pages/tables/ContractTable";
import ContractView from "../../pages/contractview/ContractView";
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import SearchResults from "../../pages/search/SearchResults";
import WaitList from "../../pages/waitlist/WaitList";
import { useAuth } from '@frontegg/react'

const Layout = (props) => {
  const { user, isAuthenticated } = useAuth();

  const [progressMessage, setProgressMessage] = useState({});
  const [contracts, setContracts] = useState([]);
  const [contractsMap, setContractsMap] = useState({});
  const [tenantContracts, setTenantContracts] = useState([]);
  const [tenantContractsMap, setTenantContractsMap] = useState({});
  const [selectedContract, setSelectedContract] = useState({});

  // Upload states
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [numProcessed, setNumProcessed] = useState(0);
  const [fileInfos, setFileInfos] = useState([]);
  const [isFilePicked, setIsFilePicked] = useState(false);
  const [isProgressing, setIsProgressing] = useState(false);
  const [selectedText, setSelectedText] = useState("");
  const [selectedTextSummary, setSelectedTextSummary] = useState("");

  const uploadStates = {
    selectedFiles: selectedFiles,
    setSelectedFiles: setSelectedFiles,
    numProcessed: numProcessed,
    setNumProcessed: setNumProcessed,
    fileInfos: fileInfos,
    setFileInfos: setFileInfos,
    isFilePicked: isFilePicked,
    setIsFilePicked: setIsFilePicked,
    isProgressing: isProgressing,
    setIsProgressing: setIsProgressing
  };

  const defaultLayoutPluginInstance = defaultLayoutPlugin();

  return (
    <div className={s.root}>
      <div className={s.wrap}>
        <Header url={props.location.pathname} selectedContract={selectedContract}/>
        <Sidebar />
        <main className={s.content}>
          <Breadcrumbs url={props.location.pathname} />
          <Switch>
            <RouteRequiresLogin path="/" exact render={() => <Redirect to="/dashboard"/>} />
            <RouteRequiresLogin path="/dashboard" render={(props) => <Profile {...props}  token={getTokenFromUser(user)} />}  />
            <RouteRequiresLogin path="/upload" render={(props) => <Upload {...props} uploadStates={uploadStates} progressMessage={progressMessage} setProgressMessage={setProgressMessage} />}  />
            <RouteRequiresLogin path="/calendar" render={(props) => <Calendar {...props} />}  />
            <RouteRequiresLogin path="/chat" render={(props) => <Chat document_id="all" token={getTokenFromUser(user)}/>}  />
            <RouteRequiresLogin path="/search/:document_id/:share/:query" render={(props) => <SearchResults {...props} pdfInstance={defaultLayoutPluginInstance}  token={getTokenFromUser(user)} embeddedInDecument='false' />}  />
            <RouteRequiresLogin path="/documents/:document_id" render={(props) => <ContractTable {...props} pdfInstance={defaultLayoutPluginInstance} contracts={contracts} setContracts={setContracts} contractsMap={contractsMap} setContractsMap={setContractsMap} token={getTokenFromUser(user)} />}  />
            <RouteRequiresLogin key="contracts" path="/documents" render={(props) => <ContractTable {...props} pdfInstance={defaultLayoutPluginInstance} contracts={contracts} setContracts={setContracts} contractsMap={contractsMap} setContractsMap={setContractsMap} token={getTokenFromUser(user)} pageName="myContracts" />} />
            <RouteRequiresLogin key="tenant_documents" path="/tenant/documents" render={(props) => <ContractTable {...props} pdfInstance={defaultLayoutPluginInstance} contracts={tenantContracts} setContracts={setTenantContracts} contractsMap={tenantContractsMap} setContractsMap={setTenantContractsMap} token={getTokenFromUser(user)} pageName="myTenantContracts" />}/>
            <RouteRequiresLogin path="/document/:document_id" render={(props) => <ContractView {...props} progressMessage={progressMessage} setProgressMessage={setProgressMessage} setSelectedContract={setSelectedContract} selectedText={selectedText} setSelectedText={setSelectedText} selectedTextSummary={selectedTextSummary} setSelectedTextSummary={setSelectedTextSummary} pdfInstance={defaultLayoutPluginInstance}  token={getTokenFromUser(user)} pageName="ownerView"/>}  />
            <Route path="/waitlist" render={(props) => <WaitList {...props} pdfInstance={defaultLayoutPluginInstance}  token={getTokenFromUser(user)} />}  />
            <Route path="/share/document/:document_id" render={(props) => <ContractView {...props} progressMessage={progressMessage} setProgressMessage={setProgressMessage} setSelectedContract={setSelectedContract}  selectedText={selectedText} setSelectedText={setSelectedText} selectedTextSummary={selectedTextSummary} setSelectedTextSummary={setSelectedTextSummary}  pdfInstance={defaultLayoutPluginInstance} token={getTokenFromUser(user)} pageName="shareView"/>}  />
            <RouteRequiresLogin path='*' exact render={() => <Redirect to="/error" />} />
          </Switch>
        </main>
        <Footer />
      </div>
    </div>
  );
}

Layout.propTypes = {
  sidebarOpened: PropTypes.bool,
  dispatch: PropTypes.func.isRequired,
}

function mapStateToProps(store) {
  return {
    sidebarOpened: store.navigation.sidebarOpened,
  };
}
function getTokenFromUser(user) {
  if(user===undefined)
    return ''
  else 
    return user.accessToken;
}
const RouteRequiresLogin = props => {
  const { user, isAuthenticated } = useAuth()
  console.log("user",user);
  let userMetadata = {}
  let isActive = false
  if(user!==undefined && user.metadata!==undefined){
    userMetadata = JSON.parse(user.metadata);
    console.log("userMetadata",userMetadata);
    if(userMetadata!==null && userMetadata.status!==undefined){
      isActive = userMetadata.status.localeCompare("active") === 0
    }
  }
  // isActive = true;
  console.log("isActive",isActive);
    return (
      <Route {...props}>{isAuthenticated ? isActive ? props.children : (<Redirect to={{ pathname: '/waitlist', }} />) : (<Redirect to={{ pathname: '/account/login', }} />)}</Route>
    );
};

export default withRouter(connect(mapStateToProps)(Layout));
