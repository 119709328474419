import React, { useState } from "react";
import { v4 as uuidv4 } from "uuid";
import {
  Col,
  Row,
  Table,
} from "reactstrap";
import Widget from "../../components/Widget/Widget.js";

import { Viewer, SpecialZoomLevel } from '@react-pdf-viewer/core';
import LinearProgress from "@material-ui/core/LinearProgress";
// Plugins
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';

// Import styles
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

class SearchResults extends React.Component {

  constructor(props) {
    super(props);
    console.log("PROPS", props);
    this.state = {
      searchResults: [],
      overallSummary: "",
      currentQuery: "",
      selectedContractID: "",
      showPDF: false,
      isLoading: false,
      contracts: [],
      contractsMap: {},
      tableCurrentPage: 0,
      tableDropdownOpen: false,
    };

  }

  doSearch = (query, document_id, share) => {
    const token = this.props.token;
    var requestBody = {
      "question": query,
      "document_uuid": document_id,
    };
    let searchUrl = 'https://docugrid-api.herokuapp.com/search';
    if (document_id.localeCompare("all") !== 0) {
      searchUrl = 'https://docugrid-api.herokuapp.com/search'
    }

    if (share == "share") {
      requestBody["is_public_document"] = true;
      searchUrl = 'https://docugrid-api.herokuapp.com/search/public'
    }
    this.setState({ isLoading: true })
    fetch(searchUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': "*",
        'Authorization': "Bearer " + token,
      },
      body: JSON.stringify(requestBody),
    })
      .then(res => res.json())
      .then(
        (result) => {
          // this.setState({"contracts": result});
          console.log("got search results", result);
          // Python code we want to translate to JS
          // docs = {}
          // # Loop through documents
          // for document in response.json()["documents"]:
          //     # save document.id->document mapping
          //     docs[document["id"]] = document
          // page_content = ""
          // i = 1
          // for answer in response.json()["answers"][:10]:
          //   # find the document summary that contains the answer
          //   content = docs[answer["document_id"]]["content"]
          //   start_offset = answer["offsets_in_document"][0]["start"]
          //   end_offset = answer["offsets_in_document"][0]["end"]
          //   # Let's expand the offset a bit to include 200 words before and after the answer
          //   start_offset = max(0, start_offset - 200)
          //   end_offset = min(len(content), end_offset + 200)
          //   content = content[start_offset:end_offset]
          //   page_content += f"\n# Search Result: [{i}]:" \
          //     +"\n# Document ID: " + answer["meta"]["name"] \
          //     +"\n# Snippet: ..." + answer["context"].replace("\n", " ") +"..." \
          //     +"\n# Content: " + content \
          //     +"\n# Score: " + str(answer["score"]) 
          //   i = i + 1
          var docs = {}
          // Loop through documents
          for (var i = 0; i < result["documents"].length; i++) {
            // save document.id->document mapping
            docs[result["documents"][i]["id"]] = result["documents"][i]
          }
          for (var j = 0; j < result["answers"].length; j++) {
            // find the document summary that contains the answer
            var answer = result["answers"][j];
            var content = docs[answer["document_id"]]["content"];
            var start_offset = answer["offsets_in_document"][0]["start"];
            var end_offset = answer["offsets_in_document"][0]["end"];
            // Let's expand the offset a bit to include 200 words before and after the answer
            start_offset = 0;
            end_offset = content.length;
            content = content.substring(start_offset, end_offset);
            answer["content"] = content;
          }
          this.setState({
            searchResults: result["answers"],
            overallSummary: result["overall_summary"],
            isLoading: false,
          });

        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          this.setState({
            isLoading: false,
          });
          console.log("error", error)
        }
      )
  }

  componentDidMount() {
    const pdfjs = require('pdfjs-dist');
    pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;
  }

  render_context_search_result = (item) => {
    var answer = item;

    var context = answer["content"];
    if (item['offsets_in_context'] !== null) {
      var offset_start = item['offsets_in_document'][0]["start"];
      var offset_end = item['offsets_in_document'][0]["end"];
    } else {
      var offset_start = context.toLowerCase().search(this.state.currentQuery.toLowerCase());
      var offset_end = offset_start + this.state.currentQuery.length;
    }
    offset_end = offset_end + 1; // as offset_end is inclusive
    var first_part_start = Math.max(0, offset_start - 200);
    var end_part_end = Math.min(context.length, offset_end + 200);
    var first_part = context.substring(first_part_start, offset_start);
    var middle_part = context.substring(offset_start, offset_end);
    var end_part = context.substring(offset_end, end_part_end);

    return (
      <td style={{ width: "75%" }}>
        {first_part}
        <span className="search_result_context" style={{ background: `rgba(136, 238, 255, 0.3)` }}>
          {middle_part}
          <span className="search_result_context_annotation"></span></span>
        {end_part}
      </td>
    )
  }
  getAnswerSubstring = (item) => {
    if (item['offsets_in_context'] !== null) {
      return item["context"].substring(item['offsets_in_context'][0]["start"], item['offsets_in_context'][0]["end"]);
    }
    return this.state.currentQuery;
  }

  render() {
    // toast(<Notification type="info" text="Render"></Notification>, {
    //   autoClose: 3000,
    //   closeButton: false,
    //   hideProgressBar: true,
    // });
    const query = decodeURIComponent(this.props.match.params.query);
    const document_id = decodeURIComponent(this.props.match.params.document_id);
    const share = decodeURIComponent(this.props.match.params.share);

    var linkPrefix = "/document";
    if (share == "share") {
      linkPrefix = "/share/document";
    }
    if (query !== undefined && query !== null && query !== "null" && query && this.state.currentQuery != query) {
      console.log("running search", query);
      this.state.currentQuery = query;
      this.setState({
        searchResults: [],
        overallSummary: "",
        isLoading: true,
      });
      // toast(<Notification type="info" text="Making query"></Notification>, {
      //   autoClose: 3000,
      //   closeButton: false,
      //   hideProgressBar: true,
      // });
      this.doSearch(this.state.currentQuery, document_id, share);
    }
    console.log("PROPS ID", query);

    const searchResults = this.state.searchResults;
    const overallSummary = this.state.overallSummary;
    let counter = 0;
    return (
      <div>
        {this.state.isLoading && <LinearProgress />}

        <Row>
          <Col>
            <Row className="mb-4">
              <Col>
                {searchResults !== undefined && searchResults.length > 0 &&
                  <Widget>
                    <Widget className="widget-p-lg">
                    <Row> 
                      <p className="headline-1 mb-0 text-muted">{this.state.currentQuery} </p> 
                    </Row>
                    <Row>
                      <div className="mb-1" style={{ fontSize: "1.2rem" }}>{overallSummary} </div>
                    </Row>
                    </Widget>
                    <div className="widget-table-overflow" style={{ fontSize: "0.7rem" }}>
                      <Table className="table-striped table-borderless table-hover" responsive>
                        <thead>

                        </thead>
                        <tbody>
                        {searchResults.map(item => {
                            // Increment the counter variable after each row is rendered
                            counter += 1;

                            // Use the counter variable in the table
                            return (
                              <tr key={uuidv4()} onClick={event => this.props.history.push(`${linkPrefix}/${item["meta"]["document_uuid"]}?clause=${encodeURIComponent(this.getAnswerSubstring(item))}`)}>
                                <td style={{width: "5%"}}> {counter} </td>
                                {this.render_context_search_result(item)}
                                {this.props.embeddedInDecument.localeCompare("true") !== 0 &&
                                  <td className="not_import_for_mobile"> {item['meta']['name']} </td>
                                }
                              </tr>
                            )
                          })}
                        </tbody>
                      </Table>
                    </div>
                  </Widget>}
              </Col>
            </Row>

          </Col>
        </Row>
      </div>
    )
  }
}

export default SearchResults;
