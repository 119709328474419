import React, { useState, useEffect, useRef } from 'react';
import styles from "./Chat.module.scss";
import docugrid_image from "../../assets/favicon.png";
import reactStringReplace from 'react-string-replace';

const ChatInterface = (props) => {
  const [input, setInput] = useState('');
  const [socket, setSocket] = useState(null);
  const messagesRef = useRef([]);
  const setMessages = (newMessages) => {
    messagesRef.current = newMessages;
  };
  const messages = messagesRef.current;
  const [citationVisible, setCitationVisible] = useState({});

  const toggleCitation = (citation) => {
    setCitationVisible({
      ...citationVisible,
      [citation]: !citationVisible[citation]
    });
  }

  const getAnswerSubstring = (item) => {
    if (item['offsets_in_context'] !== null) {
      return item["context"].substring(item['offsets_in_context'][0]["start"], item['offsets_in_context'][0]["end"]);
    }
  }

  const handleClickCitation = (citation, document_uuid, answer_substring) => {
    console.log("citation clicked");
    toggleCitation(citation);
  }

  useEffect(() => {
    const ws = new WebSocket(
      `wss://docugrid-api.herokuapp.com/chat?id=${props.document_id}&token=${props.token}`);
    setSocket(ws);

    ws.onopen = () => {
      console.log('WebSocket connection established');
    };

    ws.onmessage = (event) => {
      console.log(event);
      const json_payload = JSON.parse(event.data);
      const message = json_payload["message"];

      const characters = message.split(' ').map((char, index) => ({
        text: char,
        sender: 'server',
        delay: index * 30, // 30ms delay between each character
      }));
      // Take out the last element in messagesRef.current since we added an empty message
      // in order to animate the cursor early.
      setMessages([...messagesRef.current.slice(0, -1), { text: characters, citations: json_payload["citations"], sender: 'server' }]);
      setInput(' ');
    };

    ws.onclose = () => {
      console.log('WebSocket connection closed');
      setMessages([...messagesRef.current.slice(0, -1), { text: "Error", citations: {}, sender: 'server' }]);
      setInput(' ');
    };

    return () => {
      ws.close();
    };
  }, []);

  useEffect(() => {
    // Your code here
    console.log('Messages have changed');

  }, [messagesRef.current]);

  const handleSubmit = (event) => {
    event.preventDefault();
    socket.send(input);
    setMessages([...messages, { text: input, citations: {}, sender: 'client' }, { text: "", citations: {}, sender: 'server' }]);
    setInput('');
  };

  const handleCitationInText = (wordToRender, citations) => {
    let replacedText = wordToRender;
    Object.keys(citations).forEach(citation => {
      if (wordToRender.includes(citation)) {
        replacedText = reactStringReplace(replacedText, citation, (citation, i) => (
          <a className={styles.purple} onClick={() => handleClickCitation(citation)}>{citation}</a>
        ));
      }
    });
    // console.log(replacedText);
    return replacedText;
  }


  const render_citation = (id, item) => {
    var answer = item;

    var context = answer["context"];
    if (item['offsets_in_context'] !== null) {
      var offset_start = item['offsets_in_context'][0]["start"];
      var offset_end = item['offsets_in_context'][0]["end"];
    }
    offset_end = offset_end + 1; // as offset_end is inclusive
    var first_part = context.substring(0, offset_start);
    var middle_part = context.substring(offset_start, offset_end);
    var end_part = context.substring(offset_end);
    const className = !citationVisible[id] ? styles.hidden : '';
    const answer_substring = getAnswerSubstring(item)
    const document_uuid = item["meta"]["document_uuid"]
  
    return (
      <tr className={className} onClick={() => props.history.push(`/document/${document_uuid}?clause=${encodeURIComponent(answer_substring)}`)}>
        <td style={{ width: "5%" }}>{id}</td>
        <td style={{ width: "75%" }}>
          {first_part}
          <span className="search_result_context" style={{ background: `rgb(136, 238, 255)` }}>
            {middle_part}
            <span className="search_result_context_annotation"></span></span>
          {end_part}
        </td>
      </tr>
    )
  }

  const handleChange = (event) => {
    setInput(event.target.value);
  };

  return (
    <div className={styles.chat_interface}>
      <ul className="messages">
      {messages.map((message, index) => {
        let citations = [];
        Object.keys(message.citations).map(citation => {
          // console.log(citation);
          if (message.text.some(text => text.text.includes(citation))) {
              citations.push(<div>{render_citation(citation, message.citations[citation])}</div>);
          }
        });
        const hasVisibleCitations = Object.values(citationVisible).some(val => val === true);
        // console.log(message.text)
        return <li key={index} className={`${styles.message} ${message.sender === 'server' ? styles.server : styles.client}`}>
          <div className={styles.bubble}>
            {message.sender === 'server' ? <img src={docugrid_image} alt="Image" /> : 'K'}
          </div>
          {Array.isArray(message.text) ? (
            message.text.map((char, i) => {
              let wordToRender = char.text.replace(/\n\[/g, '<br>\[');
              return <span
                key={i}
                style={{ animationDelay: `${char.delay}ms` }}
              >{handleCitationInText(wordToRender, message.citations)} <span> </span></span>
            })
          ) : (
            <span dangerouslySetInnerHTML={{ __html: message.text.replace(/\n\[/g, '<br>\[').replace(/ /g, '&nbsp;') }} />
          )}
          <div className={`${styles.citations} ${hasVisibleCitations ? 'show': styles.hidden}`}>
            {citations}
          </div>
        </li>
      })}
      </ul>
      <form onSubmit={handleSubmit}>
        <input
          type="text"
          value={input}
          onChange={handleChange}
          placeholder="Type a message"
        />
        <button type="submit">Send</button>
      </form>
    </div>
  );
};

export default ChatInterface;