import React, { useState } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { useEffect } from "react";
import {
  Navbar,
  Nav,
  NavItem,
  NavLink,
  Badge,
  InputGroupAddon,
  InputGroup,
  Input,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Form,
  FormGroup,
  ListGroup,
  ListGroupItem,
} from "reactstrap";

import { logoutUser } from "../../actions/auth";
import { closeSidebar, openSidebar } from "../../actions/navigation";
import { AdminPortal } from '@frontegg/react'
import MenuIcon from "../Icons/HeaderIcons/MenuIcon";
import SearchBarIcon from "../Icons/HeaderIcons/SearchBarIcon";
import SearchIcon from "../Icons/HeaderIcons/SearchIcon";

import ProfileIcon from "../../assets/navbarMenus/pfofileIcons/ProfileIcon";
import MessagesIcon from "../../assets/navbarMenus/pfofileIcons/MessagesIcon";
import TasksIcon from "../../assets/navbarMenus/pfofileIcons/TasksIcon";

import logoutIcon from "../../assets/navbarMenus/pfofileIcons/logoutOutlined.svg";
import basketIcon from "../../assets/navbarMenus/basketIcon.svg";
import calendarIcon from "../../assets/navbarMenus/calendarIcon.svg";
import envelopeIcon from "../../assets/navbarMenus/envelopeIcon.svg";
import mariaImage from "../../assets/navbarMenus/mariaImage.jpg";
import notificationImage from "../../assets/navbarMenus/notificationImage.jpg";
import userImg from "../../assets/user.svg";
import { useAuth } from '@frontegg/react'
import { useAuthUser, useTenantsActions, useTenantsState } from '@frontegg/react-hooks/auth';

import s from "./Header.module.scss";
import "animate.css";
import SofiaLogo from "../Icons/SofiaLogo.js";


const Header = (props) => {
  const { user, isAuthenticated } = useAuth();
  const { tenants: tenantsFromState, loading } = useTenantsState();
  const { selectedContract } = props;
  const [suggestionsOpen, setSuggestionsOpen] = useState(true);
  const [suggestions, setSuggestions] = useState([]);

  let url = props.url;
  let routeArr = props.url.split('/');
  console.log(routeArr);
  let appPath = routeArr[1];
  let documentID = routeArr[2];
  let searchMessage = "Search All Documents";
  let shareMode = appPath === "share";
  if (shareMode) {
    documentID = routeArr[3];
  }

  const [menuOpen, setMenuOpen] = useState(false);
  const [notificationsOpen, setNotificationsOpen] = useState(false);
  const [searchText, setSearchText] = useState("");

  let inSingleDocumentView = (appPath === "document" || appPath === "share" || (appPath === "search" && documentID !== "all"));
  if (inSingleDocumentView) {
    searchMessage = "Ask anything about this document";
  }

  const toggleNotifications = () => {
    setNotificationsOpen(!notificationsOpen);
  }

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  }

  const toggleSidebar = () => {
    if (props.sidebarOpened) {
      props.dispatch(closeSidebar());
    } else {
      const paths = props.location.pathname.split('/');
      paths.pop();
      props.dispatch(openSidebar());
    }
  }


  const doLogout = () => {
    props.dispatch(logoutUser());
  }

  const doSearchWithText = (text) => {
    const encodedSearchText = encodeURIComponent(text);
    if (inSingleDocumentView && !shareMode) {
      props.history.push(`/document/${documentID}?search=${encodedSearchText}`);
    } else if (inSingleDocumentView && shareMode) {
      props.history.push(`/share/document/${documentID}?search=${encodedSearchText}`);
    } else{
      props.history.push(`/search/all/my/${encodedSearchText}`);
    }
  }

  const doSearch = (e) => {
    if (e !== undefined) {
      e.preventDefault();
    }
    doSearchWithText(searchText);
  }

  const goFileUpload = (e) => {
    e.preventDefault();
    props.history.push(`/upload`);
  }

    // Example of how to filter suggestions based on the selectedContract
    useEffect(() => {
      var questions = [];
      // now let's see if "questions" exists in the selectedContract object
      // without using "in" operator
      var prefixQuestions = searchText;
      // if the user is typing a question, then we'll only show questions that
      // start with the same prefix, but if prefix is empty, let's default to
      // "what" so that we'll show some questions
      if (prefixQuestions === "") {
        prefixQuestions = "what";
      }
      if (selectedContract && selectedContract.questions) {
        for (const question of selectedContract.questions) {
          // We'll only add questions that match the search text
          if (question.question.toLowerCase().indexOf(prefixQuestions.toLowerCase()) === -1) {
            continue;
          }
          // if the question has the sequence "generate questions", then we'll skip it
          if (question.question.toLowerCase().indexOf("generate questions") !== -1) {
            continue;
          }
          questions.push(question.question);
        }
      }
      console.log("setting suggestions to ", questions);
      // Let's limited it to 6 questions max
      // but we want to get the 6 most diverse questions, the ones that have
      // the most different prefixes. So we'll sort the questions alphabetically
      // then take 6 questions that have the most different prefixes.
      // We'll keep looping till we fill all questions.
      questions.sort();
      var suggestions = [];
      var lastPrefix = "";
      for (const question of questions) {
        const prefix = question.split(" ")[0];
        if (prefix !== lastPrefix) {
          suggestions.push(question);
          lastPrefix = prefix;
        }
        if (suggestions.length >= 6) {
          break;
        }
      }
      // if we didn't find 6 questions, we'll just add the rest
      if (suggestions.length < 6) {
        for (const question of questions) {
          if (suggestions.indexOf(question) === -1) {
            suggestions.push(question);
          }
          if (suggestions.length >= 6) {
            break;
          }
        }
      }
      setSuggestions(suggestions);
    }, [selectedContract, searchText]);
  
    const handleInputFocus = () => {
      console.log("handleInputFocus");
      setSuggestionsOpen(true);
    };
  
    const handleInputBlur = () => {
      console.log("handleInputBlur");
      setSuggestionsOpen(false);
    };
  
    const handleInputChange = (e) => {
      setSearchText(e.target.value);
      // You can also filter the suggestions based on the search text here
    };
  
    const handleSuggestionClick = (suggestion) => {
      setSearchText(suggestion);
      setSuggestionsOpen(false);
      doSearchWithText(suggestion);
    };
  
    return (
      <Navbar fixed="top" className={`${s.root} d-print-none`}>
        <div>
          <NavLink
            onClick={() => toggleSidebar()}
            className={`d-md-none mr-3 ${s.navItem}`}
            href="#"
          >
            <MenuIcon className={s.menuIcon} />
          </NavLink>
        </div>

        <Form className="d-sm-block search_bar" onSubmit={doSearch} inline>
          <FormGroup style={{width: "100%"}}>
            <InputGroup className='input-group-no-border'>
  
              <Input id="search-input" placeholder={searchMessage}
                  autoComplete="off" className='focus' value={searchText}
                  onFocus={handleInputFocus}
                  onChange={handleInputChange }/>
              <InputGroupAddon addonType="prepend">
                <span onClick={doSearch}>
                  <SearchBarIcon />
                </span>
              </InputGroupAddon>
            </InputGroup>
            {suggestionsOpen && (
                <ListGroup flush>
                  {suggestions.map((suggestion) => (
                    <ListGroupItem onClick={() => handleSuggestionClick(suggestion)}>
                      {suggestion}
                    </ListGroupItem>
                  ))}
                </ListGroup>
              )}
          </FormGroup>
        </Form>

  
        <Nav className="ml-auto" > 
          <Dropdown isOpen={notificationsOpen} toggle={() => toggleNotifications()} nav id="basic-nav-dropdown" className="ml-3">
          { user !== undefined &&
            <DropdownToggle nav caret className="navbar-dropdown-toggle">
              <span className={`${s.avatar} rounded-circle float-left mr-2`}>
                <img src={user.profilePictureUrl} alt="User"/>
              </span>
              <span className="small d-none d-sm-block ml-1 mr-2 body-1">{user.name}</span>
            </DropdownToggle> }
            { user === undefined &&
            <DropdownToggle nav caret className="navbar-dropdown-toggle">
              <span className={`${s.avatar} rounded-circle float-left mr-2`}>
              <ProfileIcon/>
              </span>
              <span className="small d-none d-sm-block ml-1 mr-2 body-1">Guest</span>
            </DropdownToggle> }
            { user !== undefined &&
            <DropdownMenu className="navbar-dropdown profile-dropdown" style={{ width: "194px" }}>
              <DropdownItem className={s.dropdownProfileItem} onClick={() => AdminPortal.show()}><ProfileIcon/><span>Profile</span></DropdownItem>
              <DropdownItem className={s.dropdownProfileItem}><MessagesIcon/><span>Messages</span></DropdownItem>
              <DropdownItem className={s.dropdownProfileItem} onClick={() => AdminPortal.show()}><TasksIcon/><span>Admin</span></DropdownItem>
              { tenantsFromState !== undefined &&
              <div>
              {/* <span>{user.tenantId}</span> */}
                { tenantsFromState.map((item, index) => (
                  <div>
                    { user.tenantId.localeCompare(item.tenantId)===0 &&
                    <span>Tenant: {item.name}</span>
                    }
                  </div>
  
  
              ))}
              </div>
              }
              <NavItem>
                <NavLink onClick={() => doLogout()} href="/account/logout">
                  <button className="btn btn-primary rounded-pill mx-auto logout-btn" type="submit"><img src={logoutIcon} alt="Logout"/><span className="ml-1">Logout</span></button>
                </NavLink>
              </NavItem>
            </DropdownMenu>}
            
            { user === undefined &&
            <DropdownMenu className="navbar-dropdown profile-dropdown" style={{ width: "194px" }}>
              <DropdownItem className={s.dropdownProfileItem} onClick={() => doLogout()} href="/account/login"><ProfileIcon/><span>Login</span></DropdownItem>
            </DropdownMenu>}
          </Dropdown>
        </Nav>
      </Navbar>
    )
  };

Header.propTypes = {
  dispatch: PropTypes.func.isRequired,
  sidebarOpened: PropTypes.bool,
}

function mapStateToProps(store) {
  return {
    sidebarOpened: store.navigation.sidebarOpened,
    sidebarStatic: store.navigation.sidebarStatic,
  };
}

export default withRouter(connect(mapStateToProps)(Header));

