import React, { useState, useEffect } from "react";
import {
  Col,
  Row,
  Button,
  Badge,
  ButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem, Dropdown,
} from "reactstrap";
import Widget from "../../components/Widget/Widget";
import TasksStepper from "./stepper/TasksStepper";
import AvatarGroup from "../../components/AvatarGroup/AvatarGroup";
import ApexCharts from "react-apexcharts";
import s from "./Profile.module.scss";
import RechartsPieChart from "../uielements/charts/components/RechartsPieChart";
import { AdminPortal } from '@frontegg/react'

import moreIcon from "../../assets/tables/moreIcon.svg";
import profileImg from "../../assets/profile/profile.png";
import heartRed from "../../assets/dashboard/heartRed.svg";
import heartTeal from "../../assets/dashboard/heartTeal.svg";

import mock from "./mock";
import Avatar from "../../components/Avatar/Avatar";
import { useAuth } from '@frontegg/react'

const { newsGroupData, avatarGroupData, apexLineChart } = mock;

export default function Profile(props) {
  const [profileDropdownOpen, setProfileDropdownOpen] = useState(false);
  const [wizardDropdownOpen, setWizardDropdownOpen] = useState(false);
  const [numContracts, setNumContracts] = useState(0);
  const [numContractsExpiring365, setNumContractsExpiring365] = useState(0);
  const [numContractsExpiring90, setNumContractsExpiring90] = useState(0);
  const [numContractsExpiring30, setNumContractsExpiring30] = useState(0);
  const { user, isAuthenticated } = useAuth();

  const profileMenuOpen = () => {
    setProfileDropdownOpen(!profileDropdownOpen);
  }

  const wizardMenuOpen = () => {
    setWizardDropdownOpen(!wizardDropdownOpen);
  }
  

  var numDaysBetween = function(d1, d2) {
    var diff = Math.abs(d1 - d2);
    return diff / (1000 * 60 * 60 * 24);
  };

  const getContractStats = () => {
    const token = user.accessToken;
    fetch(`https://docugrid-api.herokuapp.com/contracts/stats`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': "*",
        'Authorization': "Bearer " + token,
      }
    })
    .then(res => res.json())
    .then(
        (result) => {
          // this.setState({"contracts": result});
          console.log("got search contract stats", result);
          setNumContracts(result["total_contracts_count"]);
          var expiring365 = 0;
          var expiring90 = 0;
          var expiring30 = 0;

          var today = new Date();
          for (const [key, value] of Object.entries(result["documents"])) {
            console.log(`${key}: ${value}`);
            for (const [k, v] of Object.entries(value)) {
              if (k == "upcoming_expiry_date") {
                console.log(k, Date.parse(v));
                if (numDaysBetween(today,  Date.parse(v)) < 365) {
                  expiring365++;
                }
                if (numDaysBetween(today,  Date.parse(v)) < 90) {
                  expiring90++;
                }
                if (numDaysBetween(today,  Date.parse(v)) < 30) {
                  expiring30++;
                }
              }
            }
          }
          setNumContractsExpiring365(expiring365);
          setNumContractsExpiring90(expiring90);
          setNumContractsExpiring30(expiring30);

          
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          console.log("error", error)
        }
      )
  }
  const getProfileInfo = () => {
    console.log(user);
    const token = user.accessToken;
    fetch(`https://docugrid-api.herokuapp.com/users/me`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': "*",
        'Authorization': "Bearer " + token,
      }
    })
    .then(res => res.json())
    .then(
        (result) => {
          console.log("got profile info", result);          
        },
        (error) => {
          console.log("got profile info error", error)
        }
      )
  }

  useEffect(()=>{
    getProfileInfo();
    getContractStats();
  },[props.token])

  
  const chartsSettings = {
    donut: {
      data: [
        { name: 'Contracts Expiring in 12 Months', value: numContractsExpiring365, color: '#FFC405' },
        { name: 'Contracts Expiring in 90 days', value: numContractsExpiring90, color: '#4D53E0' },
        { name: 'Contracts Expiring in 30 days', value: numContractsExpiring30, color: '#FF5668' },
      ],
    }
  };

  const emptyChartSettings = {
    donut: {
      data: [
        { name: 'No Expiring Contracts', value: 1, color: '#4D53E0' },
      ],
    }
  }
  

  return(
    <div>
      <Row className="mb-4">
        <Col lg='4' sm='12' className="mb-4 mb-lg-0">
          <Widget className={`widget-p-md ${s.profile}`}>
            <div className="d-flex justify-content-end">
{/* REMOVING UNNECESSARY EDIT/DELETE MENU FROM INTRO PAGE FOR NPW */}
{/*               <Dropdown
                nav
                isOpen={profileDropdownOpen}
                toggle={() => profileMenuOpen()}
              >
                <DropdownToggle nav className="p-0">
                  <img src={moreIcon} alt="More..."/>
                </DropdownToggle>
                <DropdownMenu >
                  <DropdownItem>Edit</DropdownItem>
                  <DropdownItem>Delete</DropdownItem>
                </DropdownMenu>
              </Dropdown> */}
            </div>
            <div className={s.profileTitle}>
              <img className="mx-0 mx-md-2" src={user.profilePictureUrl} alt="..."/>
              <div className="d-flex flex-column mb-2 ml-3">
                <p className="headline-2">Welcome to Docugrid!</p>
                <p className="headline-4 mt-1 mb-1">{user.name},</p>
                <p className="headline-4 mt-1 mb-1">We're excited to show what Docugrid can do!</p>
                <hr />
              </div>
            </div>
            <div className="body-3 mb-2 base-light-gray">
            Docugrid specializes in state-of-the-art contract analysis, making it quick and easy for you to understand the legal language of any document.
              <p className="body-3 mt-3 mb-2">With our powerful AI tool, you'll be able to get insightful summaries of contracts in a fraction of the time it would normally take to read them.</p>
              <p className="body-3 mt-3 mb-2">So go ahead and try it out - we think you'll be pleasantly surprised at just how easy and fun contract reading can be!</p>
              <p className="body-3 mt-3 mb-2">Also, you can search for specific keywords or terms in the document to get more information about them. For example, if you want to know more about the "termination clause" in a contract, simply search for that term and our AI tool will pull up all the relevant information for you. So go ahead and give it a try - we think you'll be impressed!</p>
            </div>
            <br></br>
            <div className="d-flex flex-column mb-2 ml-3">
              <Button onClick={(e) => {
                e.preventDefault();
                props.history.push(`/upload`);
              }} className="rounded-pill mr-3" color="secondary-yellow">Upload contract now!</Button>
            </div>
          </Widget>

        </Col>


        <Col lg='8' sm='12'>
        <Widget className="widget-p-lg">
          <p className="headline-1 mb-0">Upload your contracts, select text, and translate legalese!</p>
          <div class="videoWrapper" style={{width: "100%", height: "100%"}}>
            <iframe width="560" height="315" style={{width: "50vw", height: "calc(100vw/3.73)"}} src="https://www.youtube.com/embed/-4GhiQJhuu8?modestbranding=1&loop=1&rel=0&autoplay=1&mute=1&playlist=-4GhiQJhuu8" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
          </div>
        </Widget>
        </Col>
      </Row>


    </div>
  )
}
