import React, { useState, useRef, useEffect } from "react";
import { v4 as uuidv4 } from "uuid";
import {
  Col,
  Row,
  Progress,
  Button,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  InputGroupAddon,
  InputGroup,
  Input,
  Form,
  FormGroup,
  Badge
} from "reactstrap";
import Widget from "../../components/Widget/Widget.js";
import StarIcon from "../../assets/badges/StarIcon.js";
import upgradeImage from "../../assets/dashboard/upgradeImage.svg";
import LinearProgress from "@material-ui/core/LinearProgress";
import { ToastContainer, toast } from 'react-toastify';
import Notification from "../../components/Notification/Notification.js";

import s from "./Upload.module.scss";
import { useAuth } from '@frontegg/react';


const Upload = (props) => {

  const { user, isAuthenticated } = useAuth();
  const [checkboxes, setCheckboxes] = useState([true, false]);
  const [time, setTime] = useState(Date.now());
  // const [selectedFile, setSelectedFile] = useState();
  const selectedFiles = props.uploadStates.selectedFiles;
  const setSelectedFiles = props.uploadStates.setSelectedFiles;
  const fileInfos = props.uploadStates.fileInfos;
  const setFileInfos = props.uploadStates.setFileInfos;
  const isFilePicked = props.uploadStates.isFilePicked;
  const setIsFilePicked = props.uploadStates.setIsFilePicked;
  const isProgressing = props.uploadStates.isProgressing;
  const setIsProgressing = props.uploadStates.setIsProgressing;
  const filePicker = useRef();

  // Refresh every second.
  useEffect(() => {
    const interval = setInterval(() => setTime(Date.now()), 1000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  const fileChangeHandler = (event) => {
    console.log("file selected", event.target.files)

    let duplicateFile = {};
    if (event.target.files.length > 0) {
      setSelectedFiles(event.target.files);
      setIsFilePicked(true);
    }
  };

  const uploadFile = (e) => {
    e.preventDefault();
    console.log(filePicker);
    filePicker.current.files = [];
    setIsFilePicked(false);

    props.setProgressMessage((prevState) => ({
      ...prevState,
      message: { "message": "Uploading..." }
    }));

    const formData = new FormData();
    let processingCount = selectedFiles.length;
    setIsProgressing(true);
    for (let selectedFile of selectedFiles) {
      fetch(
        'https://docugrid-api.herokuapp.com/contracts',
        {
          method: "POST",
          headers: {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': "*",
            'Authorization': "Bearer " + user.accessToken,
          },
          body: JSON.stringify({
            "filename": selectedFile["name"],
            "filetype": selectedFile["type"],
          }),
        }
      )
        .then((response) => response.json())
        .then((getAssetResult) => {
          console.log('Success:', getAssetResult, "proceeding with upload");
          formData.append('File', selectedFile);
          fetch(
            getAssetResult["asset_url"],
            {
              method: 'PUT',
              headers: {
                'Content-Type': selectedFile["type"]
              },
              body: selectedFile,
            }
          )
            .then((uploadResult) => {
              if (uploadResult.ok) {
                console.log('Success:', uploadResult);
                fileInfos.push({ name: selectedFile["name"], url: '/document/' + getAssetResult["document_id"], document_id: getAssetResult["document_id"] })
                processFile(getAssetResult["document_id"], getAssetResult["asset_fetch_url"], getAssetResult["filename"]);
              } else {
                props.setProgressMessage((prevState) => ({
                  ...prevState,
                  message: { "message": "Failed to upload" },
                  [getAssetResult["document_id"]]: { "message": "Failed to upload" },
                }));
              }
            })
            .catch((error) => {
              console.error('Error:', error);
            });
        })
        .catch((error) => {
          console.error('Error:', error);
        });
    }
    setSelectedFiles([]);
    setIsProgressing(false);
  }

  const processFile = (document_id, asset_fetch_url, filename) => {
    props.setProgressMessage((prevState) => ({
      ...prevState,
      message: { "message": "Processing..." },
      [document_id]: { "message": "Processing..." },
    }));
    // fetch(
    //   `https://docugrid-flask.ngrok.io/process_contract`,
    //   {
    //     method: "POST",
    //     headers: {
    //       'Content-Type': 'application/json',
    //       'Access-Control-Allow-Origin': "*",
    //       'Authorization': "Bearer " + user.accessToken,
    //     },
    //     body: JSON.stringify({
    //       "document_id": document_id,
    //       "asset_url": asset_fetch_url,
    //       "filename": filename,
    //     }),
    //   }
    // )
    fetch(
      `https://docugrid-api.herokuapp.com/contracts/process_contract`,
      {
        method: "POST",
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': "*",
          'Authorization': "Bearer " + user.accessToken,
        },
        body: JSON.stringify({
          "document_id": document_id,
          "asset_url": asset_fetch_url,
          "filename": filename,
        }),
      }
    )
      .then(res => res.json())
      .then(
        (result) => {
          setContractLoop(document_id);
        },
        (error) => {
          console.log("error", error)
        }
      )
      .catch((error) => {
        console.error('failed processing contract Error:', error);
        props.setProgressMessage((prevState) => ({
          ...prevState,
          message: { "message": "Error" },
          [document_id]: { "message": "Error" },
        }));
      });
  }


  function setContractLoop(document_id) {
    console.log("getContractLoop");

    let count = 0;
    let contractStatus = 'not-ready';
    let isRunning = true;
    let readyForSearch = false;
    let clausesDone = false;
    let questionsDone = false;
    let summaryDone = false;
    let messageToDisplay = 'Processing the document';
    let previousProgressMessage = null;
    let delay = 3000;
    const myInterval = setInterval(() => {
      count = (count + 1);
      console.log("count:", count + 1)
      getContract(document_id).then(
        (contractResponse) => {
          if (contractResponse !== null && contractResponse !== undefined) {
            if (('status' in contractResponse)) {
              contractStatus = contractResponse['status']
              if (contractStatus.localeCompare("ready") !== 0) {
              } else {
                isRunning = false
                clearInterval(myInterval);
              }
              if ('status_details' in contractResponse) {
                for (const statusDetailIndex in contractResponse['status_details']) {
                  let statusDetail = contractResponse['status_details'][statusDetailIndex]
                  if ('status_name' in statusDetail) {
                    if (statusDetail['status_name'].localeCompare("indexing_for_search") === 0) {
                      readyForSearch = true;
                      messageToDisplay = 'Parsing key clauses';
                      // OKAY FOR NOW, let's just cheat and skip all other steps and only index for searching.
                      summaryDone = true;
                      messageToDisplay = 'All done!';
                    }
                    else if (statusDetail['status_name'].localeCompare("clauses_parsing") === 0) {
                      clausesDone = true;
                      messageToDisplay = 'Generating questions';
                    }
                    else if (statusDetail['status_name'].localeCompare("questions_generation") === 0) {
                      questionsDone = true;
                      messageToDisplay = 'Generating Summary';
                    }
                    else if (statusDetail['status_name'].localeCompare("summary_generation") === 0) {
                      summaryDone = true;
                      messageToDisplay = 'All done!';
                    }
                  }
                }
              }
              let progressMessage = {
                "ready_for_search": readyForSearch,
                "cuad_done": clausesDone,
                "questions_done": questionsDone,
                "all_done": contractStatus.localeCompare("ready") === 0,
                "message": messageToDisplay,
                "status_progress": contractResponse['status_progress'],
              };
              if (previousProgressMessage === null || (previousProgressMessage !== null && JSON.stringify(previousProgressMessage) !== JSON.stringify(progressMessage))) {
                console.log('Received', progressMessage);
                previousProgressMessage = progressMessage;
                const options = {
                  autoClose: 4000,
                  closeButton: false,
                  hideProgressBar: true,
                  position: toast.POSITION.TOP_RIGHT,
                };
                props.setProgressMessage((prevState) => ({
                  ...prevState,
                  message: progressMessage,
                  [document_id]: progressMessage
                }));
              }
            }

          }
        },
        (error) => {
          console.log("error", error)
        }
      );

      if (count === 110) {
        isRunning = false
        clearInterval(myInterval);
      }
    }, isRunning ? delay : null);
  }

  const getContract = (document_id) => {
    let urlToFetch = 'https://docugrid-api.herokuapp.com/contracts?id=';
    return fetch(urlToFetch + document_id, {
      method: 'GET',
      headers: {
        'Authorization': "Bearer " + user.accessToken,
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': "*",
      },
    })
      .then(res => res.json())
      .then(
        (result) => {
          // console.log("got contract result", result);
          if ((!'document_id' in result)) {
            console.log("document_id missing", result);
            return null;
          }
          return result;
        },
        (error) => {
          console.log("error", error)
        }
      )
  };

  const toggleCheckbox = (id) => {
    setCheckboxes(checkboxes => checkboxes
      .map((checkbox, index) => index === id ? !checkbox : checkbox))
  }

  // console.log(props.progressMessage);
  return (
    <div>
      <Row>
        <Col >

          <Row className="gutter mb-4">
            <Col xs={12}>
              <Widget className="widget-p-none">
                {isProgressing && <LinearProgress />}
                <div className="d-flex flex-wrap align-items-center justify-content-center">
                  <div className="d-flex flex-column align-items-center col-12 col-xl-6 p-sm-4">
                    {<i className={'eva eva-cloud-upload-outline'} />}
                    <p className="headline-1">Upload your PDF contracts</p>
                    <p className="headline-3">And our platform will prepare your contracts for search.</p>
                    <br></br><Form onSubmit={uploadFile} >
                      <FormGroup>
                        <InputGroup className='input-group-no-border'>

                          <Input type="file" id="file-input" autoComplete="off" className='focus' accept="application/pdf" ref={filePicker} onChange={fileChangeHandler} multiple />
                          <InputGroupAddon addonType="append">
                          </InputGroupAddon>

                        </InputGroup>
                      </FormGroup>
                    </Form>
                    <div className="d-flex justify-content-between my-4">
                      {isFilePicked && <Button onClick={uploadFile} className="rounded-pill mr-3" color="primary">Upload</Button>}
                      {isFilePicked && <Button onClick={event => props.history.goBack()} className="rounded-pill body-3" outline color="dark">Cancel</Button>}
                    </div>
                  </div>
                  <div className="d-flex justify-content-center col-12 col-xl-6">
                    <img className="p-1 img-fluid" src={upgradeImage} alt="..." />
                  </div>
                </div>
                <div className="card">
                  <div className="card-header">List of Documents</div>
                  <ul className="list-group list-group-flush">
                    {fileInfos &&
                      fileInfos.map((file, index) => (
                        <li className="list-group-item" key={index}>
                          <Row className="mb-2" onClick={event => props.history.push(file.url)}>
                            <Col>
                              <a target='_blank'>{file.name} {props.progressMessage[file.document_id]["ready_for_search"] && <Badge style={{ "marginLeft": "10px" }} href="" pill color="success"><StarIcon />Ready for searching</Badge>}</a>
                              <a target='_blank'>{props.progressMessage[file.document_id]["cuad_done"] && <Badge style={{ "marginLeft": "10px" }} href="" pill color="success"><StarIcon />Key Clauses Extracted!</Badge>}</a>
                              <a target='_blank'>{props.progressMessage[file.document_id]["questions_done"] && <Badge style={{ "marginLeft": "10px" }} href="" pill color="success"><StarIcon />Questions Generated!</Badge>}</a>
                              <a target='_blank'>{props.progressMessage[file.document_id]["all_done"] && <Badge style={{ "marginLeft": "10px" }} href="" pill color="success"><StarIcon />Fully Processed</Badge>}</a>
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <LinearProgress variant='determinate' value={props.progressMessage[file.document_id]["status_progress"] || 0}></LinearProgress>
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              {props.progressMessage[file.document_id]["message"]}
                            </Col>
                          </Row>
                        </li>
                      ))}
                  </ul>
                </div>

              </Widget>
            </Col>
          </Row>

        </Col>

      </Row>
    </div>
  )
}

export default Upload;
