import { Fragment, useState, useEffect } from 'react'

import classnames from 'classnames'
import { Row, Col } from 'reactstrap'

import CalendarBody from "./components/CalendarBody"
import SidebarRight from "./components/SidebarRight";
import AddEventSidebar from "./components/AddEventSidebar";

import { useSelector, useDispatch } from "react-redux"
import {
  fetchEvents,
  selectEvent,
  updateEvent,
  updateFilter,
  updateAllFilters,
  addEvent,
  removeEvent
} from "../../actions/calendar";
import { useAuth } from '@frontegg/react'

const calendarsColor = {
  Expiring: 'danger',
  Signed: 'success',
}

// {
//   "events": [
//     {
//       "id": 1,
//       "url": "",
//       "title": "Design Review",
//       "start": "2022-04-02T01:55:22.484Z",
//       "end": "2022-04-03T01:55:22.484Z",
//       "allDay": false,
//       "extendedProps": {
//         "calendar": "Business"
//       }
//     },
//     {
//       "id": 2,
//       "url": "",
//       "title": "Meeting With Client",
//       "start": "2022-04-19T07:00:00.000Z",
//       "end": "2022-04-20T07:00:00.000Z",
//       "allDay": true,
//       "extendedProps": {
//         "calendar": "Business"
//       }
//     },
//     {
//       "id": 3,
//       "url": "",
//       "title": "Running Training",
//       "allDay": true,
//       "start": "2022-04-21T07:00:00.000Z",
//       "end": "2022-04-23T07:00:00.000Z",
//       "extendedProps": {
//         "calendar": "Holiday"
//       }
//     },
//     {
//       "id": 4,
//       "url": "",
//       "title": "Visa Application at UK Embassy",
//       "start": "2022-04-19T07:00:00.000Z",
//       "end": "2022-04-20T07:00:00.000Z",
//       "allDay": true,
//       "extendedProps": {
//         "calendar": "Personal"
//       }
//     },
//     {
//       "id": 5,
//       "url": "",
//       "title": "Crossfit",
//       "start": "2022-04-17T07:00:00.000Z",
//       "end": "2022-04-18T07:00:00.000Z",
//       "allDay": true,
//       "extendedProps": {
//         "calendar": "Personal"
//       }
//     },
//     {
//       "id": 6,
//       "url": "",
//       "title": "Weekly Meeting",
//       "start": "2022-04-17T07:00:00.000Z",
//       "end": "2022-04-18T07:00:00.000Z",
//       "allDay": true,
//       "extendedProps": {
//         "calendar": "Flatlogic"
//       }
//     },
//     {
//       "id": 7,
//       "url": "",
//       "title": "Product Review",
//       "start": "2022-04-17T07:00:00.000Z",
//       "end": "2022-04-18T07:00:00.000Z",
//       "allDay": true,
//       "extendedProps": {
//         "calendar": "Business"
//       }
//     },
//     {
//       "id": 8,
//       "url": "",
//       "title": "Meeting With Alex",
//       "start": "2022-05-01T07:00:00.000Z",
//       "end": "2022-05-01T07:00:00.000Z",
//       "allDay": true,
//       "extendedProps": {
//         "calendar": "Business"
//       }
//     },
//     {
//       "id": 9,
//       "url": "",
//       "title": "Monthly Checkup",
//       "start": "2022-03-01T08:00:00.000Z",
//       "end": "2022-03-01T08:00:00.000Z",
//       "allDay": true,
//       "extendedProps": {
//         "calendar": "Personal"
//       }
//     }
//   ],
//   "selectedEvent": {},
//   "selectedCalendars": [
//     "Business",
//     "Personal",
//     "Holiday",
//     "Flatlogic"
//   ]
// }

const Calendar = (props) => {
  const dispatch = useDispatch()
  const { user, isAuthenticated } = useAuth();
  const [loaded, setLoaded] = useState(false);
  const [store, setStore] = useState({
    "events": [
      {
      }
    ],
    "selectedEvent": {},
    "selectedCalendars": [
      "Expiring",
      "Signed"
    ]
  });
  
  const getContractStats = () => {
    const token = user.accessToken;
    fetch(`https://docugrid-api.herokuapp.com/contracts/stats`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': "*",
        'Authorization': "Bearer " + token,
      }
    })
    .then(res => res.json())
    .then(
        (result) => {
          // this.setState({"contracts": result});
          console.log("got search contract stats", result);
          var events = [];

          var today = new Date();
          for (const [document_id, doc] of Object.entries(result["documents"])) {
            console.log(`${document_id}: ${doc}`);
            var document_name = doc["Document Name"];
            if (doc["Document Name"] === undefined) {
              document_name = "CONTRACT";
            }
            var parties = doc["Parties"];
            if (doc["Parties"] === undefined) {
              document_name = "Parties";
            }

            for (const [k, v] of Object.entries(doc)) {
              console.log(k, v);
              if (k == "start_date") {
                console.log("START DATE PUSH" , events.length);
                events.push(      {
                  "id": events.length,
                  "url": "",
                  "contract_url": "/document/"+ document_id,
                  "title": "Signed" + " with " + parties,
                  "start": v,
                  "end": v,
                  "allDay": true,
                  "extendedProps": {
                    "calendar": "Signed"
                  }
                })
              }
              if (k == "end_date") {

              }
              if (k == "upcoming_expiry_date") {
                console.log("EXP DATE PUSH", events.length);

                events.push(      {
                    "id": events.length,
                    "url": "",
                    "contract_url": "/document/"+ document_id,
                    "title": "Expiring" + " with " + parties,
                    "start": v,
                    "end": v,
                    "allDay": true,
                    "extendedProps": {
                      "calendar": "Expiring"
                    }
                  })
                }
            }
          }

          setLoaded(true);
          setStore({
            "events": events,
            "selectedEvent": {},
            "selectedCalendars": [
              "Expiring",
              "Signed"
            ]
          });
          console.log("calendar events", events);
          
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          console.log("error", error)
        }
      )
  }


  const filters = [
    { label: 'Expiring', color: 'danger', className: 'styled mb-1' },
    { label: 'Signed', color: 'primary', className: 'styled mb-1' },
  ]
  
  const [addSidebarOpen, setAddSidebarOpen] = useState(false)
  const [rightSidebarOpen, setRightSidebarOpen] = useState(false)
  const [calendarApi, setCalendarApi] = useState(null)

  const handleAddEventSidebar = () => setAddSidebarOpen(!addSidebarOpen)
  const toggleSidebar = val => setRightSidebarOpen(val)

  const blankEvent = {
    title: '',
    start: '',
    end: '',
    allDay: false,
    url: '',
    extendedProps: {
      calendar: '',
      guests: [],
      location: '',
      description: '',
    }
  }

  const refetchEvents = () => {
    if (calendarApi !== null) {
      calendarApi.refetchEvents()
    }
  }

  useEffect(() => {
    dispatch(fetchEvents(store.selectedCalendars))
  }, [])

  if (!loaded) {
    getContractStats();
  }

  return (
    <Fragment>
      <div className="app-calendar overflow-hidden">
        <Row noGutters>

          <Col className="position-relative mr-3">
            <CalendarBody
              history={props.history}
              store={store}
              dispatch={dispatch}
              blankEvent={blankEvent}
              calendarApi={calendarApi}
              setCalendarApi={setCalendarApi}
              calendarsColor={calendarsColor}
              toggleSidebar={toggleSidebar}
              selectEvent={selectEvent}
              updateEvent={updateEvent}
              handleAddEventSidebar={handleAddEventSidebar}
            />
          </Col>
          <Col
            id='app-calendar-sidebar'
            className={classnames('col app-calendar-sidebar flex-grow-0 overflow-hidden d-flex flex-column', {
              show: rightSidebarOpen
            })}
          >
            <SidebarRight
              filters={filters}
              store={store}
              dispatch={dispatch}
              updateFilter={updateFilter}
              updateAllFilters={updateAllFilters}
              toggleSidebar={toggleSidebar}
              handleAddEventSidebar={handleAddEventSidebar}
            />
          </Col>
          <div
            className={classnames("body-content-overlay", {
              show: rightSidebarOpen === true
            })}
            onClick={() => toggleSidebar(false)}
          />
        </Row>
      </div>
      <AddEventSidebar
        store={store}
        dispatch={dispatch}
        open={addSidebarOpen}
        handleAddEventSidebar={handleAddEventSidebar}
        selectEvent={selectEvent}
        addEvent={addEvent}
        removeEvent={removeEvent}
        refetchEvents={refetchEvents}
        updateEvent={updateEvent}
        calendarApi={calendarApi}
        calendarsColor={calendarsColor}
      />
    </Fragment>
  )
}

export default Calendar;
