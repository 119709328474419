import React from 'react';

class SofiaLogo extends React.Component {

  render() {
    return (
      <img width="90%" src="https://ucarecdn.com/7f06894f-4993-41b3-98f9-f63a16e1a82e/" />
    );
  }
}

export default SofiaLogo;
