import React from "react";
import FooterIcon from "../Icons/FooterIcon";
import FooterIconCoffee from "../Icons/FooterIconCoffee";
import s from "./Footer.module.scss";

const Footer = () => {
  return (
    <div className={s.footer}>
      <span className={s.footerLabel}>2023 &copy; Docugrid. Powered by</span>
      <FooterIconCoffee />
      <span className={s.footerLabel}>, crafted with</span> <FooterIcon />
    </div>
  )
}

export default Footer;
