import React, { useState } from 'react';
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Input,
  ModalFooter
} from 'reactstrap';
import Code from "../../components/Code/Code.js";
import ReactDiffViewer from 'react-diff-viewer'

export default function CustomModal(props) {

  const {
    className,
    modalObject,
    scrollable,
    size,
    children,
    backdrop,
    opened,
    subscribing,
    modalTitle,
    onClose,
    onAcceptChange
  } = props;

  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal)

  const CloseBtn = <i className="eva eva-close cursor-pointer" onClick={onClose}/>

  return (
    <>
      <Modal isOpen={modal || opened} backdrop={backdrop} toggle={toggle} size={size} className={className} scrollable={scrollable}>
        <ModalHeader toggle={toggle} close={CloseBtn} className="headline-1">{modalObject['title']}</ModalHeader>
        {scrollable
          ? <ModalBody>
          {modalObject['body']}
         </ModalBody>
          : <ModalBody>
          { 'second_body' in modalObject && <ReactDiffViewer
            oldValue={modalObject['body']}
            newValue={modalObject['second_body']}
            splitView={false}
            hideLineNumbers={true}
            disableWordDiff={true}
          /> }
          { 'url' in modalObject && <div>
          {modalObject['body']}</div>}
          
          { 'url' in modalObject &&
          <div>

          <Input 
                  type="text"
                  readOnly
                  className="form-control-plaintext" 
                  value={modalObject['url']} /></div>}
          </ModalBody>
        }
        <ModalFooter>
          {
            subscribing
              ? <div className="mx-auto">
                <Button className="btn-rounded btn-outline-secondary mr-3" outline onClick={onClose}>Cancel</Button>
                <Button className="btn-rounded" color="primary" onClick={onClose}>Subscribe</Button>
              </div>
              : <div  className="mx-auto">
                { 'second_body' in modalObject && <Button className="btn-rounded btn-outline-secondary mr-3" outline onClick={() => {onAcceptChange(modalObject['type'],modalObject['second_body'])}}>Accept change</Button>}
                { 'url' in modalObject && <Button className="btn-rounded btn-outline-secondary mr-3" outline onClick={() => {navigator.clipboard.writeText(modalObject['url'])}}>Copy to clipboard</Button>}
                <Button className="btn-rounded btn-outline-secondary mr-3" outline onClick={onClose}>Close</Button>
              </div>
          }
        </ModalFooter>
      </Modal>
    </>
  )
}
