
const language_mappings = {
  "start_date_relevant_text":"Start Date",
  "id":"Document id",
  "initial_end_date_relevant_text":"Initial End Date",
  "upcoming_expiry_date":"Upcoming Expiry Date",
  "start_date":"Start Date",
  "initial_end_date":"Initial End Date",
  "auto_renew_contract":"Auto Renew",
  "compliance_assignment":"Assignment",
  "compliance_audit":"Audit",
  "compliance_auto_renew":"Automatic Renewal",
  "compliance_payment_overdue_interest":"Payment - interest on overdue remittance",
  "compliance_governing_law":"Governing Law and Venue",
  "compliance_payment_timeframe":"Payment Timeframe",
  "compliance_termination":"Termination",
  "compliance_exclusivity":"Exclusivity",
  "compliance_term":"Term",
  "compliance_liquidated_damages":"Liquidated Damages",
}
const category_mappings = {
  "compliance_assignment":"Revise",
  "compliance_audit":"Revise",
  "compliance_auto_renew":"Delete",
  "compliance_payment_overdue_interest":"Revise",
  "compliance_governing_law":"Revise",
  "compliance_payment_timeframe":"Revise",
  "compliance_termination":"Add",
  "compliance_exclusivity":"Delete",
  "compliance_term":"Add",
  "compliance_liquidated_damages":"Add",
}

export function getPrettyName(key) {
  if (key in language_mappings)
    return language_mappings[key];
  else
    return key;
}

export function getComplianceCategory(key) {
  if (key in category_mappings)
    return category_mappings[key];
  else
    return 'Revise';
}

export function getContextValueKey(key,infoArray) {
  console.log(key,infoArray)
  if ((key+"_relevant_text") in infoArray)
    return key+"_relevant_text";
  else
    return key;
}

export function basename(path) {
  return path.split('/').reverse()[0];
}

export function sleep(time){
    return new Promise((resolve)=>setTimeout(resolve,time)
  )
}

export function getProgressValue(fileProgressPayload) {
  if (fileProgressPayload === undefined) {
    return 0;
  }
  if ("expected_duration_seconds" in fileProgressPayload) {
    var time_elapsed = Date.now() - fileProgressPayload["time_started"];
    var remaining_time = fileProgressPayload["expected_duration_seconds"]*1000.0;
    var progress = time_elapsed / (time_elapsed+remaining_time);
    if (progress > 1.0) {
      progress = 1.0
    }
    progress = progress * 100.0
    console.log("Progress is ", Math.trunc(progress).toString(), "time_elapsed is ",time_elapsed, "remaining time is ", remaining_time);
    return Math.trunc(progress);
  }
  return 0;
}