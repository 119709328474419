import React, { useState, useEffect} from "react";
import * as ReactDOM from 'react-dom';
import { v4 as uuidv4 } from "uuid";
import {
  Col,
  Row,
  Dropdown,
  DropdownMenu,
  DropdownToggle,
  DropdownItem,
  Badge,  
  Progress,
  Nav,
  NavItem,
  NavLink,
  Button,
  FormGroup,
  Label,
  Input,
  InputGroup,
  InputGroupAddon
} from "reactstrap";
import Widget from "../../components/Widget/Widget.js";
import CustomModal from "../../components/CustomModal/CustomModal.js";


import ApexCharts from "react-apexcharts";
import heartRed from "../../assets/dashboard/heartRed.svg";
import cloudIcon from "../../assets/tables/cloudIcon.svg";
import funnelIcon from "../../assets/tables/funnelIcon.svg";
import optionsIcon from "../../assets/tables/optionsIcon.svg";
import printerIcon from "../../assets/tables/printerIcon.svg";
import searchIcon from "../../assets/tables/searchIcon.svg";
import moreIcon from "../../assets/tables/moreIcon.svg";
import ProfileIcon from "../../assets/navbarMenus/pfofileIcons/ProfileIcon";
import "eva-icons/style/eva-icons.css";

import $ from 'jquery';
import s from "./ContractView.module.scss";

import {
  basename,
  sleep,
  getProgressValue,
  getPrettyName,
  getComplianceCategory,
  getContextValueKey,
} from "../../actions/utils";

import { LoadError, Viewer, SpecialZoomLevel} from '@react-pdf-viewer/core';
import { searchPlugin,FlagKeyword } from '@react-pdf-viewer/search';
import { LayerRenderStatus, Plugin, PluginOnCanvasLayerRender, Button as PDFButton,PrimaryButton, Position, Tooltip} from '@react-pdf-viewer/core';


// Plugins
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import { highlightPlugin, MessageIcon, SelectionData } from '@react-pdf-viewer/highlight';
// Import styles
import '@react-pdf-viewer/highlight/lib/styles/index.css';

// Import styles
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import Notification from "../../components/Notification/Notification.js";
import { useParams } from "react-router-dom";
import LinearProgress from "@material-ui/core/LinearProgress";
import { pageNavigationPlugin } from '@react-pdf-viewer/page-navigation';
import { CircularProgress } from "@material-ui/core";
import SearchResults from "../search/SearchResults.js";
import Chat from "../chat/Chat.js";

const ContractView = (props) => {
  const pageNavigationPluginInstance = pageNavigationPlugin();
  return <ContractViewImpl pageNavigationPluginInstance={pageNavigationPluginInstance} {...props} />
}

const overview_clauses = ["Counterparty", "Contract Owner", "Effective Date", "Parties", "auto_renew_contract", "initial_end_date", "start_date", "upcoming_expiry_date"]
const clauses_to_hide = ["id", "start_date_context_text", "initial_end_date_context_text", "start_date_relevant_text", "initial_end_date_relevant_text"]

const getChartSeries = (compliance_clause) => {
  const parsed_value = parseFloat(compliance_clause.parsed_value)
  const suggested_value = parseFloat(compliance_clause.suggested_value)
  return [{
    name: "Current Agreement",
    data: [{
      x: parsed_value,
      y: 0,
      x_label: parsed_value,
    }]
  },{
    name: "Standard for my industry peers",
    data: [{
      x: suggested_value,
      y: suggested_value === parsed_value ? 2 : 0,
      x_label: suggested_value,
    }]
  },{
    name: "Standard for my counterparty peers",
    data: [{
      x: suggested_value==((suggested_value+parsed_value)/2).toFixed()?(suggested_value*1.2).toFixed():((suggested_value+parsed_value)/2).toFixed(),
      y: 0,
      x_label: ((suggested_value+parsed_value)/2).toFixed()?(suggested_value*1.2).toFixed():((suggested_value+parsed_value)/2).toFixed(),
    }]
  }]
};
const getChartOptions = (compliance_clause) => {
  const parsed_value = parseFloat(compliance_clause.parsed_value)
  const suggested_value = parseFloat(compliance_clause.suggested_value)
  let max = 10
  if (suggested_value>=10 || parsed_value>=10)
  { max = 20 }
  if (suggested_value>=20 || parsed_value>=20)
  { max = 50 }
  if (suggested_value>=50 || parsed_value>=50)
  { max = 100 }
  if (suggested_value>=100 || parsed_value>=100)
  { max = 1000 }
  const tick_value = 1
  const max_value = max
  const min_value = 0
  console.log({      tickAmount: (tick_value),
  min:min_value.toFixed(),
  max:max_value.toFixed()
})
  return {
    chart: {
      height: "100px",
      width: "100%",
      type: 'scatter',
      toolbar: {
        show: false
      },
      zoom: {
        enabled: true,
        type: 'x'
      },
    },
    colors: ['#4d53e0', '#FF5668', '#43BC13'],
    markers: {
      size: 14,
    },
    xaxis: {
      type:'numeric',
      tickAmount: tick_value,
      min:min_value,
      max:max_value
    },
    yaxis: {
      show:false,
      showForNullSeries:false,
      floating: true,
      stroke: {
        color: '#00000000',
      },
      axisTicks: {
        show: false
      },
      axisBorder: {
        show: false
      },
      labels: {
        show: false
      },
    }, grid: {
      show: true,     
      xaxis: {
        lines: {
          show: false
         }
       },  
      yaxis: {
        lines: { 
          show: false 
         }
       },   
    },
    dataLabels: {
      enabled: false,
    },
    tooltip: {
      enabled: true,
      y: {
        show: false,
        formatter: function(value, { series,seriesIndex,dataPointIndex, w }) {
          // console.log("formattery",seriesIndex,value,w.config.series[seriesIndex])
          return w.config.series[seriesIndex].data[dataPointIndex].x_label
        }
      }
    },
  }
};



class ContractViewImpl extends React.Component {

  constructor(props) {
    super(props);
    console.log("PROPS", props);
    this.state = {
      selectedContractID: "",
      selectedContractRealID: "",
      showPDF: false,
      contract: undefined,
      isProgressing: true,
      isAuthenticated: false,
      isOwnerView: false,
      modalObject: {},
      showModal: false,
      previousProgressMessage: "",
      activeTab: "Overview",
      previousClause: "",
      previousQuery: "",
      selectedText: "",
      selectedTextSummary: "",
      summaryFeedbackText: ""
    };
  }



  toggleTab = (tab) => {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      })
    }
  }

  showContract(id) {
    console.log("showContract", id);
  }

  truncateString(str, num) {
    if (str.length <= num) {
      return str
    }
    return str.slice(0, num) + '...'
  }
  truncateStringFromCenter(str, num) {
    if (str.length <= num) {
      return str
    }
    return str.slice(0, num/2) + '...' + str.slice(str.length-num/2, str.length) 
  }

  base64toBlob = (data) => {
      // Cut the prefix `data:application/pdf;base64` from the raw base 64
      const base64WithoutPrefix = data.substr('data:application/pdf;base64,'.length);

      const bytes = atob(base64WithoutPrefix);
      let length = bytes.length;
      let out = new Uint8Array(length);

      while (length--) {
          out[length] = bytes.charCodeAt(length);
      }

      return new Blob([out], { type: 'application/pdf' });
  };

  getContract = (document_id) => {
    console.log("getContract");
    this.setState({
      "isProgressing": true,
    });
    const token = this.props.token;

    if(token !== undefined && token.length>1)
    {
      this.state.isAuthenticated = true;
      if(this.props.pageName.localeCompare("ownerView") === 0){
        console.log("ownerView");
        this.state.isOwnerView = true;
      }
    }
    let urlToFetch = 'https://docugrid-api.herokuapp.com/contracts/public?id='
    if(this.state.isOwnerView){
      urlToFetch = 'https://docugrid-api.herokuapp.com/contracts?id=';
    }
    fetch(urlToFetch+document_id, {
      method: 'GET',
      headers: {
        'Authorization': "Bearer " + token,
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': "*",
      },
    })
    .then(res => res.json())
    .then(
        (result) => {
          // this.setState({"contracts": result});
          console.log("got contract result", result);
          if(!('document_id' in result))
          {
            this.props.history.push(`/error`);
            return;

          }
          if('compliance_check_results' in result)
          {
            result['compliance_check_results_original'] = JSON.parse(JSON.stringify(result['compliance_check_results']))
          }
          if('legal_summary' in result)
          {
            result["legal_summary"].map((clause) => {
              clause['context_trimmed'] = this.truncateString(clause['context'], 100);
            });            
          }

          this.setState({
            isLoaded: true,
            contract: result,
            isProgressing: false,
          });

        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          console.log("error", error)
        }
      )
  };

  onChangeCheck = (event) => {
    console.log(event);
  };

  deleteContractFromSearchIndex = (document_id) => {
    const token = this.props.token;

    fetch(`https://docugrid-flask.ngrok.io/delete_contract_from_search_index`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': "*",
        'Authorization': "Bearer " + token,
      },
      body: JSON.stringify({
        "document_id": document_id,
      }),
    })
    .then(res => res.json())
    .then(
        (result) => {
          // this.setState({"contracts": result});
          console.log("delete contract from search index", result);
          this.setState({
            isProgressing: false,
          });
          this.props.history.push(`/documents`);
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          console.log("error", error)
        }
      )

  };
  hideModal = () => {
    this.setState({ showModal: false });
  };
  acceptChange = (rule,text_to_accept) => {
    console.log("acceptChange",rule,text_to_accept)
    this.state.contract["compliance_check_results"].forEach((item) => {
      if(item.value_type.localeCompare(rule)===0){
        item.in_compliance = 1;
        item.suggested_text = text_to_accept;
        item.predicted_value = "Complies with policy: update clause with suggested text"

      }
    });
    this.setState({ showModal: false });
  };
  deleteContract = (document_id) => {
    const token = this.props.token;

    this.setState({
      isProgressing: true,
    });

    fetch(`https://docugrid-api.herokuapp.com/contracts?document_id=`+document_id, {
      method: 'DELETE',
      headers: {
        'Authorization': "Bearer " + token,
      }
    })
    .then(res => res.json())
    .then(
        (result) => {
          // this.setState({"contracts": result});
          console.log("delete contract from DB", result);
          this.deleteContractFromSearchIndex(document_id);
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          console.log("error", error)
        }
      )

  };

  shareContractViaLink = (document_id) => {
    const token = this.props.token;

    this.setState({
      isProgressing: true,
    });

    fetch(`https://docugrid-api.herokuapp.com/contracts/share`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': "*",
        'Authorization': "Bearer " + token,
      },
      body: JSON.stringify({
        "document_id": document_id,
        "share_type": 'public',
        "share_to": '*',
      }),
    })
    .then(res => res.json())
    .then(
        (result) => {
          console.log("got share result", result);
          
          const localModalObject = {
            title : 'Share access',
            body : 'Anyone on the internet with this link can view',
            url : 'https://app.docugrid.io/share/document/'+result['share_id'],
            buttonText : 'Okay'
          }
          this.setState({
            isProgressing: false,
            modalObject: localModalObject,
            showModal: true
          });
          
          // this.setState({"contracts": result});
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          console.log("error", error)
        }
      )

  };



  updateTextForCompliance = (document_id,orginal_text,rule,parsed_value) => {
    const token = this.props.token;

    this.setState({
      isProgressing: true,
    });

    fetch(`https://docugrid-api.herokuapp.com/contracts/compliance_suggestion`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': "*",
        'Authorization': "Bearer " + token,
      },
      body: JSON.stringify({
        "id": document_id,
        "orginal_text": orginal_text,
        "rule": rule,
        "parsed_value": parsed_value,
      }),
    })
    .then(res => res.json())
    .then(
        (result) => {
          console.log("got compliance reword result", result);
          
          const localModalObject = {
            title : getPrettyName(rule),
            body : orginal_text,
            type : rule,
            second_body :result['result']['suggested_text'].trim(),
            buttonText : 'Okay'
          }
          this.setState({
            isProgressing: false,
            modalObject: localModalObject,
            showModal: true
          });
          
          // this.setState({"contracts": result});
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          console.log("error", error)
        }
      )

  };


  processFile = (document_id, asset_fetch_url, filename, steps_to_perform) => {
    const token = this.props.token;
    fetch(
      `https://docugrid-api.herokuapp.com/contracts/process_contract`,
      {
        method: "POST",
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': "*",
          'Authorization': "Bearer " + token,
        },
        body: JSON.stringify({
          "document_id": document_id,
          "asset_url": asset_fetch_url,
          "filename": filename,
          "steps_to_perform": steps_to_perform,
        }),
      }
    )
      .then(res => res.json())
      .then(
        (result) => {
          console.log("retrigged the document processing",result);
        },
        (error) => {
          console.log("error", error)
        }
      )
      .catch((error) => {
        console.error('failed reprocessing contract Error:', error);
      });
  }

  componentDidMount() {
    const pdfjs = require('pdfjs-dist');
    pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;
    console.log("PROPS ID" , this.props.match.params.document_id);
    this.getContract(this.props.match.params.document_id);
    // this.interval = setInterval(() => this.setState({ time: Date.now() }), 1000);
  }
  componentDidUpdate(prevProps, prevState) {
    Object.entries(this.props).forEach(([key, val]) =>
      prevProps[key] !== val && console.log(`Prop '${key}' changed`)
    );
    if (this.state) {
      Object.entries(this.state).forEach(([key, val]) =>
        prevState[key] !== val && console.log(`State '${key}' changed`)
      );
    }
  }
  componentWillUnmount() {
    clearInterval(this.interval);
  }


highlightClause(clause, shouldScroll)  {
    
    console.log("jumping to", clause);
    let regexStr = new RegExp(clause.replaceAll(/[^a-zA-Z0-9 ]/g,'.?').replaceAll(' ',".? ? ? ?"), "gi");
    console.log(regexStr)
    this.props.search(regexStr);
  }


  checkNumberBetween(number, a, b, inclusive) {
    var min = Math.min(a, b),
      max = Math.max(a, b);
  
    return inclusive ? number >= min && number <= max : number > min && number < max;
  }
  
  getComplainceMetrics(compliance_array) {
    const alignmentScore = (compliance_array.filter(obj => obj.in_compliance > 0).length*100/compliance_array.length).toFixed();
    const riskColor = alignmentScore>=70 ? 'success' : alignmentScore>=40 ? 'warning' : 'danger';
    const riskText = alignmentScore>=70 ? 'Low' : alignmentScore>=40 ? 'Medium' : 'High';
    return { 'alignment_score' : alignmentScore, 'risk_color': riskColor, 'risk_text': riskText}
  }

  submitRating = (text, summary, rating) => {
    
    console.log("submit rating " + rating);
    console.log(this.props.selectedTextSummary)
    console.log(summary)
    let rating_score = 1
    if (rating === 'thumbs-up') {
      $('#rating-widget').html('<div class="widget-p-lg">We will give you more summaries like this!</div>');
      rating_score = 10
    } else {
      $('#rating-widget').html('<div class="widget-p-lg">Okay, we understand we need to do a better job next time.</div>');
    }
    const document_id = this.props.match.params.document_id
    const token = this.props.token;
    fetch(`https://docugrid-api.herokuapp.com/summarize_feedback`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': "*",
        'Authorization': "Bearer " + token,
      },
      body: JSON.stringify({
        "document_id": document_id,
        "summary_output": summary,
        "clause": text,
        "feedback_rating": rating_score,
        "feedback_text": this.state.summaryFeedbackText
      }),
    })
    .then(res => res.json())
    .then(
        (result) => {
          console.log("submmited feedbak for summary:", result);
          this.state.summaryFeedbackText = '';
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          console.log("error", error)
        }
      )
  }

  handleSummaryFeedbackChange = (event) =>{
    this.state.summaryFeedbackText = event.target.value
    // console.log('value is:', event.target.value);
    // console.log('summaryFeedbackText is:', this.state.summaryFeedbackText);
  };
  handleDocumentLoad = (e) => {
    $('.rpv-core__inner-pages--vertical').animate({
      scrollTop: $('.rpv-core__inner-pages--vertical').scrollTop() + 10000
    });
    $('.rpv-core__inner-pages--vertical').animate({
      scrollTop: $('.rpv-core__inner-pages--vertical').scrollTop()
    });
    console.log("error loading pdf")

    const clause = new URLSearchParams(this.props.location.search).get('clause');
    if (clause !== undefined && clause !== null && clause !== "") {
      sleep(1000).then(()=>{
        this.highlightClause(clause, true);
      });
    }

    const { activateTab } = this.props.defaultLayoutPluginInstance;
    activateTab(0);
  };
  handleDocumentLoadingError = (error) => {
    console.log("error loading pdf")
    let message = '';
    let fallbackToIframe=false;
    switch (error.name) {
        case 'InvalidPDFException':
            message = 'The document is invalid or corrupted';
            fallbackToIframe = true;
            break;
        case 'MissingPDFException':
            message = 'The document is missing';
            break;
        case 'UnexpectedResponseException':
            message = 'Unexpected server response';
            break;
        default:            
            fallbackToIframe = true;
            message = 'Cannot load the document';
            break;
    }
    if(fallbackToIframe){
          let documentEncodedURI = encodeURIComponent(this.state.contract.asset_url)
          let iframeURL = "https://docs.google.com/gview?url="+documentEncodedURI+"&embedded=true"
      return (
        <iframe src={iframeURL} style={{height: "1000px",width: "100%",  overflow: 'hidden'}}></iframe>
      );
    }
    return (
        <div
            style={{
                alignItems: 'center',
                display: 'flex',
                height: '100%',
                justifyContent: 'center',
            }}
        >
            <div
                style={{
                    backgroundColor: '#e53e3e',
                    borderRadius: '0.25rem',
                    color: '#fff',
                    padding: '0.5rem',
                }}
            >
                {message}
            </div>
        </div>
    );
  };

  get_summary_reduction_text = () => {
    if (this.props.selectedText === undefined || this.props.selectedTextSummary === undefined) {
      return
    }

    const words_original = this.props.selectedText.split(' ').length;
    const words_summary = this.props.selectedTextSummary.split(' ').length;
    const word_reduction = words_original - words_summary;
    if (words_summary <= 1) { 
      return
    }
    return <div>
      <div style={{"display": "inline"}}> {words_original}</div> words ➡️ <div style={{"color": "green", "display": "inline"}}>{words_summary}</div> words (<div style={{"color": "green", "display": "inline"}}>{(word_reduction/words_original).toFixed(2)*100}%</div> reduction in words)
    </div>
  }

  render() {
    var selectedContract = undefined;
    const selectedContractID = this.props.match.params.document_id;
    var pdfURL = "";
    var searchResultShareMode = "my";

    var progressValue = 0;
    var progressMessage = "";
    if (selectedContractID !== undefined && this.state.contract !== undefined && this.state.contract !== undefined) {
      pdfURL = this.state.contract.asset_url;
      this.state.selectedContractRealID = this.state.contract.document_id;
      // this.setState({selectedContractRealID: this.state.contract.document_id});
      selectedContract = this.state.contract;
      // console.log("selectedContractvalues", selectedContract["values"]);
      // selectedContract["values"].forEach((item) => console.log(item) )
      if (this.state.isOwnerView) {
        searchResultShareMode = "my";
      } else {
        searchResultShareMode = "share";
      }
      progressValue = getProgressValue(this.props.progressMessage[selectedContractID]);
      if (selectedContractID in this.props.progressMessage) {
        progressMessage = this.props.progressMessage[selectedContractID]["message"];
      }
    }
    if (this.state.selectedContractRealID in this.props.progressMessage &&
      this.state.previousProgressMessage !== this.props.progressMessage[this.state.selectedContractRealID]["message"]) {                    
            // If we have some progress related to this document, then trigger another contract fetch.
          this.getContract(this.props.match.params.document_id);
          this.state.previousProgressMessage = this.props.progressMessage[this.state.selectedContractRealID]["message"];
    }
    const query = new URLSearchParams(this.props.location.search).get('search');
    const match = {
      params: {
        query: query,
        document_id: selectedContractID,
        share: searchResultShareMode
      }
    }
    this.props.documentInfoSet(selectedContractID);
    if (query !== undefined && query !== null && query !== "null" && query !== this.state.previousQuery) {
      console.log("query is", query, "toggling search tab");
      this.toggleTab('Search');
      this.setState({
        previousQuery: query
      });
      this.props.setSelectedText("");
    }

    const clause = new URLSearchParams(this.props.location.search).get('clause');
    if (clause !== undefined && clause !== null && clause !== "" && clause != this.state.previousClause) {

      this.highlightClause(clause, true);
      this.setState({
        previousClause: clause
      });
    }
  
    const tableCurrentPage = this.state.tableCurrentPage;
    const tableDropdownOpen = this.state.tableDropdownOpen;


    const tableMenuOpen = () => {
      this.setState({'tableDropdownOpen': !tableDropdownOpen});
    }
    const overviewReady = selectedContract !== undefined && selectedContract["values"]["Summary"] !== undefined;
    const keyClausesReady = selectedContract !== undefined && selectedContract["clauses"] !== undefined &&  selectedContract["clauses"].length != 0;
    const questionsReady = selectedContract !== undefined && selectedContract["questions"] !== undefined &&  selectedContract["questions"].length != 0;
    this.props.setSelectedContract(selectedContract);
  
    return (
      <div>
        {this.state.isProgressing && <LinearProgress />}
        { selectedContract !== undefined &&
            <Row>
              <Col >
              {this.state.activeTab === 'Search' && 
                      <SearchResults match={match} token={this.props.token} history={this.props.history} embeddedInDecument='true' />}
              <Row className="gutter">
                <Col className="mb-4" xs={6} lg={5}>
                    <div className="headline-2">{basename(selectedContract["filename"])}</div>
                </Col>
                <Col className="mb-4" xs={6} lg={1}>
                   { (this.state.isOwnerView)  &&<Dropdown
                      className="d-sm-block"
                      style={{display: "block"}}
                      nav
                      isOpen={tableDropdownOpen}
                      toggle={() => tableMenuOpen()}
                    >
                      <DropdownToggle nav>
                        <img className="d-sm-block" src={moreIcon} alt="More..."/>
                      </DropdownToggle>
                       <DropdownMenu >
                       <DropdownItem>
                          <div  onClick={event => this.shareContractViaLink(selectedContractID)}>Share via link</div>
                        </DropdownItem>
                        { (this.state.isOwnerView && this.state.contract.is_owner)  && <DropdownItem>
                          <div  onClick={event => this.processFile(selectedContractID, pdfURL, selectedContract["filename"], "clauses")}>Re-process Clause/Compliance</div>
                        </DropdownItem>
                        }
                        { (this.state.isOwnerView && this.state.contract.is_owner)  && <DropdownItem>
                          <div  onClick={event => this.processFile(selectedContractID, pdfURL, selectedContract["filename"], "search-index")}>Re-process the document</div>
                        </DropdownItem>
                        }
                        { (this.state.isOwnerView && this.state.contract.is_owner)  && <DropdownItem>
                          <div  onClick={event => this.deleteContract(selectedContractID)}>Delete</div>
                        </DropdownItem>
                        }
                      </DropdownMenu>
                   </Dropdown>}
                   {selectedContract["values"]["Summary"] === undefined && <Badge color='secondary-yellow'>{this.props.progressMessage["document_id"] === this.state.selectedContractRealID && this.props.progressMessage["message"]}</Badge>}
                   <CustomModal
                            modalObject = {this.state.modalObject}
                            opened = {this.state.showModal}
                            onClose={this.hideModal}
                            onAcceptChange={this.acceptChange}
                          > 
                          </CustomModal>
                   </Col>
               </Row>
               <Row style={{overflow: 'scroll'}}>
                  {/* <Chat history={this.props.history} document_id={this.props.match.params.document_id} token={this.props.token}></Chat> */}

                  {this.props.selectedText === '' && <Widget
                    className="widget-p-lg"
                    style={{width:"100%"}}
                    title={
                      <p className="headline-2 mb-0 text-muted">Search or select text to summarize!</p>
                    }>

                    </Widget>}
                  {/* {this.props.selectedText === '' && !this.state.isAuthenticated && <Widget
                    className="widget-p-lg"
                    style={{width:"100%"}}
                    title={
                      <p className="headline-2 mb-0 text-muted"><a href="/account/sign-up"><ProfileIcon/><span>Sign-up</span></a> or <a href="/account/login"><ProfileIcon/><span>Login</span></a> to View Summary!</p>
                    }>
                    </Widget>} */}



                  {this.props.selectedText !== ''  && <Widget
                    className="widget-p-lg"
                    style={{width:"100%"}}
                    title={
                      <p className="headline-2 mb-0 text-muted">Summary {this.get_summary_reduction_text()}</p>
                    }>
                    {this.props.selectedTextSummary}
                    </Widget>}
                  {this.props.selectedTextSummary !== '' && <Widget
                        id="rating-widget"
                        className="widget-p-lg"
                        style={{width:"100%"}}
                        title={<p className="headline-2 mb-0 text-muted">Rate this summary</p>}>

                        <FormGroup>
                          <Label for="summary-feedback-text">What do you think of this summary ?</Label>
                          <Input type="text" id="summary-feedback-text" name="summary-feedback-text" 
                          onChange={this.handleSummaryFeedbackChange}/>
                          <p className="help-block body-3 muted">Let us know what you liked about it or what you didn't</p>
                        </FormGroup>
                        <Button onClick={() => this.submitRating(this.props.selectedText, this.props.selectedTextSummary, "thumbs-up")} className="rounded-pill body-3" outline color="dark">👍</Button>
                        <Button onClick={() => this.submitRating(this.props.selectedText, this.props.selectedTextSummary, "thumbs-down")} className="rounded-pill body-3" outline color="dark">👎</Button>
                        
                      </Widget>}

                      {this.props.selectedText !== '' && <Widget
                    className="widget-p-lg"
                    style={{width:"100%"}}
                    title={
                      <p className="headline-2 mb-0 text-muted">Selected Text</p>
                    }>
                    {this.props.selectedText}

                    </Widget>}
                  </Row>
                <Row className="gutter mb-4">
                  <Col className="mb-4" xs={12} lg={12}>
                      <div  style={{
                            border: '1px solid rgba(0, 0, 0, 0.3)',
                            display: 'flex',
                            height: '1000px',
                            overflow: 'hidden',
                        }}>

                        <div
                            style={{
                                flex: '1 1 auto',
                                overflow: 'hidden',
                            }}
                        >
                        <Viewer
                            fileUrl={pdfURL}
                            defaultScale={SpecialZoomLevel.PageFit}
                            plugins={[this.props.pageNavigationPluginInstance, this.props.searchPluginInstance, this.props.customCanvasPluginInstance, this.props.defaultLayoutPluginInstance, this.props.highlightPluginInstance]}
                            onDocumentLoad={this.handleDocumentLoad}
                            renderError={this.handleDocumentLoadingError}
                        />
                      </div>
                      </div>

                  </Col>

                </Row>
              </Col>
            </Row>
        }
      
      </div>
    )
  }
}
function withMyHook(Component) {
  return function WrappedComponent(props) {
    
    const message = '';
    const notes  = [];
    let document_id  = '';

    const defaultLayoutPluginInstance =  defaultLayoutPlugin({
      sidebarTabs: (defaultTabs) =>
          [
            // defaultTabs[0]
          ],
    });
  
    const renderHighlightTargetWrapper = (parent) => {

        return (props) => {
          console.log(props.selectedText);
          parent.setSelectedText(props.selectedText);
          parent.setSelectedTextSummary("");
          // console.log("parentprops");
          // console.log(document_id);
          // console.log(props)
          if (props.selectedText !== '') {
            // fetch(`https://docugrid-genius.ngrok.io/api/predict`, {
            var summarize_endpoint = "https://docugrid-api.herokuapp.com/summarize";
            if(parent.token === undefined || parent.token.length<=1) {
              summarize_endpoint = "https://docugrid-api.herokuapp.com/public/summarize";
            }
            fetch(summarize_endpoint, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': "*",
                'Authorization': "Bearer " + parent.token,
              },
              body: JSON.stringify({
                "data": [props.selectedText],
                "document_id": document_id,
              }),
            })
            .then(res => res.json())
            .then(
                (result) => {
                  // this.setState({"contracts": result});
                  console.log("generated summary:", result);
                  
                  const item = {
                    'id' : notes.length,
                    'summary' : result["data"][0],
                    'context_trimmed': props.selectedText,
                    'context': props.selectedText,
                };
                parent.setSelectedTextSummary(result["data"][0][0]);
                },
                // Note: it's important to handle errors here
                // instead of a catch() block so that we don't swallow
                // exceptions from actual bugs in components.
                (error) => {
                  console.log("error", error)
                }
              )
              
              props.cancel();
          }

          return (
          <div
              style={{
                  background: '#eee',
                  display: 'flex',
                  position: 'absolute',
                  left: `${props.selectionRegion.left}%`,
                  top: `${props.selectionRegion.top + props.selectionRegion.height}%`,
                  transform: 'translate(0, 8px)',
                  zIndex: 1,
              }}
          >
              <Tooltip
                  position={Position.TopCenter}
                  target={
                      <PDFButton onClick={props.toggle}>
                          <MessageIcon />
                      </PDFButton>
                  }
                  content={() => <div style={{ width: '100px' }}>Summarize this</div>}
                  offset={{ left: 0, top: -8 }}
              />
          </div>
      )}
    };

  const renderHighlightContentWrapper = (parent) => {


    return (props) => {
          parent.setSelectedText(props.selectedText);
          console.log(props.selectedText);
          const AddSummaryItem = () => {
              console.log(parent);
              parent.setSelectedText(props.selectedText);

              if (props.selectedText !== '') {
                fetch(`https://docugrid-genius.ngrok.io/api/predict`, {
                  method: 'POST',
                  headers: {
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': "*",
                  },
                  body: JSON.stringify({
                    "data": [props.selectedText],
                  }),
                })
                .then(res => res.json())
                .then(
                    (result) => {
                      // this.setState({"contracts": result});
                      console.log("generated summary:", result);
                      
                      const item = {
                        'id' : notes.length,
                        'summary' : result["data"][0],
                        'context_trimmed': props.selectedText,
                        'context': props.selectedText,
                    };
                    parent.setSelectedTextSummary(result["data"][0]);
                    },
                    // Note: it's important to handle errors here
                    // instead of a catch() block so that we don't swallow
                    // exceptions from actual bugs in components.
                    (error) => {
                      console.log("error", error)
                    }
                  )
                  
                  props.cancel();
              }
          };

          return (
              <div
                  style={{
                      background: '#fff',
                      border: '1px solid rgba(0, 0, 0, .3)',
                      borderRadius: '2px',
                      padding: '8px',
                      position: 'absolute',
                      left: `${props.selectionRegion.left}%`,
                      top: `${props.selectionRegion.top + props.selectionRegion.height}%`,
                      zIndex: 1,
                  }}
              >
                  <div>Summarize this section for me
                  </div>
                  <div
                      style={{
                          display: 'flex',
                          marginTop: '8px',
                      }}
                  >
                      <div style={{ marginRight: '8px' }}>
                          <PrimaryButton onClick={AddSummaryItem}>Summarize</PrimaryButton>
                      </div>
                      <PDFButton onClick={props.cancel}>Cancel</PDFButton>
                  </div>
              </div>
          );
      };
    }


    const customCanvasPlugin = (): Plugin => {
        const onCanvasLayerRender = (e: PluginOnCanvasLayerRender) => {
            // Return if the canvas isn't rendered completely
            if (e.status !== LayerRenderStatus.DidRender) {
                return;
            }

            // `e.ele` is the canvas element
            const canvas = e.ele;

            const ctx = canvas.getContext('2d');
            const centerX = canvas.width / 2;
            const centerY = canvas.height / 2;

            const fonts = ctx.font.split(' ');
            const fontSize = parseInt(fonts[0], 10);

            ctx.textAlign = 'center';
            ctx.font = `${fontSize * e.scale * 4}px ${fonts[1]}`;

            ctx.fillStyle = '#000';
            ctx.fillText(message, centerX, 100);
        };

        return {
            onCanvasLayerRender,
        };
    };
  
    const searchPluginInstance = searchPlugin({
        onHighlightKeyword: (props: OnHighlightKeyword) => {
            // props.highlightEle.style.outline = '2px dashed blue';
            // props.highlightEle.style.backgroundColor = 'rgba(0, 0, 0, .1)';
        },
    });
    const customCanvasPluginInstance = customCanvasPlugin();
    const { highlight, jumpToNextMatch, jumpToPreviousMatch } = searchPluginInstance;
    const search = (keyword) => {
      highlight(keyword);
      jumpToNextMatch();
    };
    const documentInfoSet = (document_id_received) => {
      document_id = document_id_received;
    };

  const renderHighlightContent = renderHighlightContentWrapper(props);
  const renderHighlightTarget = renderHighlightTargetWrapper(props);
  const highlightPluginInstance = highlightPlugin({
    renderHighlightTarget,
    renderHighlightContent,
  });
    return <Component {...props} highlight={highlight} searchPluginInstance={searchPluginInstance} jumpToNextMatch={jumpToNextMatch} search={search}  customCanvasPluginInstance={customCanvasPluginInstance} defaultLayoutPluginInstance={defaultLayoutPluginInstance} highlightPluginInstance={highlightPluginInstance} documentInfoSet={documentInfoSet}/>;
  }
}
export default withMyHook(ContractView);
