// -- React and related libs
import React from "react";
import { Switch, Route, Redirect } from "react-router";
import { HashRouter, BrowserRouter } from "react-router-dom";

// -- Redux
import { connect } from "react-redux";

// -- Custom Components
import LayoutComponent from "./components/Layout/Layout";
import ErrorPage from "./pages/error/ErrorPage";
import Login from "./pages/login/Login";
import Register from "./pages/register/Register";

// -- Redux Actions
import { loginUser, logoutUser } from "./actions/auth";

// -- Third Party Libs
import { ToastContainer } from "react-toastify";

// -- Services
// import isAuthenticated from "./services/authService";

import { useAuth } from '@frontegg/react'

// -- Component Styles
import "./styles/app.scss";
import LogRocket from 'logrocket';

const App = (props) => {
  const { user, isAuthenticated } = useAuth();
  if (user !== undefined){
  LogRocket.identify(user.id, {
    name: user.name,
    email: user.email,
  
    // Add your own custom user variables here, ie:
    tenants: user.tenants,
  });
  }

  return (
    <div>
      <ToastContainer/>
      <BrowserRouter>
        <Switch>
          <Route path="/" exact render={() => <Redirect to="/dashboard" />} />
          <Route path="/" dispatch={props.dispatch} render={(props) => <LayoutComponent {...props} />} />
          <Route path="/login" exact component={Login} />
          <Route path="/error" exact component={ErrorPage} />
          <Route path="/register" exact component={Register} />
          <Route component={ErrorPage}/>
          <Route path='*' exact={true} render={() => <Redirect to="/error" />} />
        </Switch>
      </BrowserRouter>
    </div>
  );
}

const mapStateToProps = state => ({
  isAuthenticated: state.auth.isAuthenticated,
});

export default connect(mapStateToProps)(App);
